function settingFn(condition) {
  let setting = condition.displaySettings
  let type = setting.mode
  if (setting.mode == 'doubleAxis') {
    type = 'multiaxialBar'
  } else if (setting.mode == 'multiAxisLine') {
    type = 'multiaxialLine'
  }
  return {
    type: type,
    nameCN: setting.title,
    nameEN: setting.entitle,
    styleSet: setting,
    filterSet: condition.resultFilter,
  }
}
// 分组
function groupsFn(condition) {
  let inject = condition.injectedArguments
  if (inject && inject.selectedGroups) {
    return inject.selectedGroups
  } else {
    return condition.selectedGroups
  }
}
//对比
function contrastTypeFn(condition) {
  let inject = condition.injectedArguments
  if (inject && inject.contrastType) {
    return inject.contrastType
  } else {
    return condition.contrastType
  }
}

const Format = {
  // 属性
  attr(params) {
    this.prop = params.condition.resultFilter.columns
    this.groups = groupsFn(params.condition)
    this.result = params.result.current.result
    this.contrastType = contrastTypeFn(params.condition)
    this.setting = settingFn(params.condition)
  },
  // 指标
  propfun(item, temp, arr) {
    arr.push(temp)

    // 新增对比指标（旧数据不含对比指标）
    if (this.contrastType !== 'nocontrast') {
      let alias = item.code
      switch (this.contrastType) {
        case 'contrast':
          arr.push({ alias: `${alias}_contrast`, title: '对比值', usage: 'Contrast' })
          arr.push({ alias: `${alias}_contrastRate`, title: '对比', usage: 'Rate' })
          break
        case 'last':
          arr.push({ alias: `${alias}_last`, title: '同比值', usage: 'Contrast' })
          arr.push({ alias: `${alias}_lastRate`, title: '同比', usage: 'Rate' })
          break
        case 'circle':
          arr.push({ alias: `${alias}_circle`, title: '环比值', usage: 'Contrast' })
          arr.push({ alias: `${alias}_circleRate`, title: '环比', usage: 'Rate' })
          break
        case 'lastAndCircle':
          arr.push({ alias: `${alias}_last`, title: '同比值', usage: 'Contrast' })
          arr.push({ alias: `${alias}_lastRate`, title: '同比', usage: 'Rate' })
          arr.push({ alias: `${alias}_circle`, title: '环比值', usage: 'Contrast' })
          arr.push({ alias: `${alias}_circleRate`, title: '环比', usage: 'Rate' })
          break
      }
    }
  },
}

export default Format
