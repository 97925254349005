function settingFn(condition) {
  let setting = condition[0].displaySettings
  let settingGroup = condition[0].displaySettingGroup
  let type = setting.mode
  if (type == 'doubleAxis') {
    type = 'multiaxial2'
  }
  if(type == 'table'){
    setting.enablePivot = setting.enablePivot?setting.enablePivot:'closed'
    setting.aggregation = setting.aggregation?setting.aggregation:'none'
  }
  
  let columns = condition[0].resultFilterCol
  return {
    type: type,
    nameCN: setting.title,
    nameEN: setting.entitle,
    styleSet: setting,
    filterSet: {columns:columns},
  }
}

const Contrast = {
  // 属性
  attr(params) {
    this.prop = params.condition[0].resultFilterCol
    this.groups = params.condition[0].selectedGroups
    this.result = params.result.current
    this.setting = settingFn(params.condition)
    // 对比率需要算
    this.contrastType = 'contrast'
    
  },
  // 指标
  propfun(item, temp, arr) {
    if (item.code.includes('_right')) {
      return
    }
    arr.push(temp)

    // 新增对比指标（旧数据不含对比指标）
    if (this.contrastType !== 'nocontrast') {
      let alias = item.code
      switch (this.contrastType) {
        case 'contrast':
          arr.push({ alias: `${alias}_contrast`, title: '对比值', usage: 'Contrast' })
          arr.push({ alias: `${alias}_contrastRate`, title: '对比', usage: 'Rate' })
          break
      }
    }
  },
  // 分组
  // 数据
  result(r, item, temp) {
    let k = item.alias
    let v = r[k + '_right']
    temp[k + '_contrast'] = v
    let Rate = undefined
    if (v) {
      Rate = (((r[k] - v) / v) * 100).toFixed(2) + '%'
    }
    temp[k + '_contrastRate'] = Rate
  },
}

export default Contrast
