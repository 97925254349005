function settingFn(condition) {
  let setting = condition.displaySettings
  let type = setting.mode
  return {
    type: type,
    nameCN: setting.title,
    nameEN: setting.entitle,
    styleSet: setting,
    filterSet: condition.resultFilter,
  }
}

// 指标初始值
function propsFn(tableColumns) {
  let { code, text, format } = tableColumns[tableColumns.length - 1]
  return [{ code, text, type: format }]
}

// 分组
function groupsFn(g) {
  let arr = []
  g.forEach(item => {
    let { code, text, type, value } = item
    arr.push({ code, text, type: type, value })
  })
  return arr
}

const Format = {
  // 属性
  attr(params) {
    this.prop = propsFn(params.condition.tableColumns)
    this.groups = groupsFn(params.condition.selectedGroups)
    this.result = params.result.current
    this.contrastType = params.condition.contrastType
    this.setting = settingFn(params.condition)
  },
  // 指标
  propfun(item, temp, arr) {
    arr.push(temp)

    // 新增对比指标（旧数据不含对比指标）
    if (this.contrastType !== 'nocontrast') {
      let alias = item.code
      switch (this.contrastType) {
        case 'contrast':
          arr.push({ alias: `${alias}_contrast`, title: '对比值', usage: 'Contrast' })
          arr.push({ alias: `${alias}_contrastRate`, title: '对比', usage: 'Rate' })
          break
        case 'last':
          arr.push({ alias: `${alias}_last`, title: '同比值', usage: 'Contrast' })
          arr.push({ alias: `${alias}_lastRate`, title: '同比', usage: 'Rate' })
          break
        case 'circle':
          arr.push({ alias: `${alias}_circle`, title: '环比值', usage: 'Contrast' })
          arr.push({ alias: `${alias}_circleRate`, title: '环比', usage: 'Rate' })
          break
        case 'lastAndCircle':
          arr.push({ alias: `${alias}_last`, title: '同比值', usage: 'Contrast' })
          arr.push({ alias: `${alias}_lastRate`, title: '同比', usage: 'Rate' })
          arr.push({ alias: `${alias}_circle`, title: '环比值', usage: 'Contrast' })
          arr.push({ alias: `${alias}_circleRate`, title: '环比', usage: 'Rate' })
          break
      }
    }
  },
}

export default Format
