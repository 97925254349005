import drows from '@/script/bookmarkV2/drowChartV2'
import drows1 from './drowChart'
import store from '@/store'

export function Behavior(_this, query, data, opts, tbdata, hotmapdata, chart) {
  let basicInfo = store.state.basicInfo
  let setting = query.settings
  let { styleSet, propSet } = setting
  const propSetprops = propSet?.props ?? []
  let option = {}
  let labelColor = basicInfo.project.theme == 0 ? '#444' : '#ddd'
  let copyColumns = JSON.parse(JSON.stringify(data.columns))
  let copyRows = JSON.parse(JSON.stringify(data.rows))
  let num_pattern = /^(-?\d+)(\.\d+)?$/

  let allprops = copyColumns.filter(x => {
    return x.usage !== 'Group'
  })
  let groups = copyColumns.filter(x => {
    return x.usage === 'Group'
  })
  groups.map(x => {
    if (x.dataType === 'Boolean') {
      copyRows.map(r => {
        r[x.alias] = x.title + ':' + r[x.alias]
        return r
      })
    }
    return x
  })

  //给 copyRows 每个数据增加一个 group_key 代替 sys_gen_key
  copyRows.forEach(x => {
    let group_key = ''
    let other_key = ''
    groups.forEach((g, idx) => {
      group_key += x[g.alias] + ','
      if (idx > 0) {
        other_key += x[g.alias] + ','
      }
    })
    group_key = group_key.substr(0, group_key.length - 1)
    other_key = other_key.substr(0, other_key.length - 1)
    x.group_key = group_key
    x.otherGroupname = other_key
  })

  let measureColumns = allprops.filter(x => {
    return x.usage === 'Measure'
  })

  // 是否有分组
  let hasGroup = groups.length > 0 && groups[0].title != '总体'

  //分组第一项集合  X轴坐标轴
  let firstGroupList = []
  let setArr = new Set()
  //给每一条添加 除去第一项的分组名集合
  let otherGroupList = []
  let setArr2 = new Set()
  copyRows.forEach(item => {
    let arr = item.group_key.split(',')
    if (hasGroup) setArr.add(arr[0])
    let group_key = arr.slice(1).join(',')
    setArr2.add(group_key)
  })
  firstGroupList = [...setArr]
  otherGroupList = [...setArr2]

  let tails = ['_contrast', '_last', '_circle']
  let contrastLabels = {
    _contrast: '对比',
    _last: '同比',
    _circle: '环比',
  }

  //去掉比率
  let chartprops = allprops.filter(x => {
    return !x.alias.includes('Rate')
  })

  //修改名称
  // chartprops = chartprops.map(x => {
  //   tails.map(t => {
  //     if (x.alias.includes(t)) {
  //       let old = chartprops.filter(e => {
  //         return e.alias === x.alias.replace(t, '')
  //       })
  //       x.title = old[0] ? old[0].title + '-' + x.title : x.title
  //     }
  //   })
  //   return x
  // })
  switch (setting.type) {
    case 'card':
      getCard()
      break
    case 'pie':
      getPie()
      break
    case 'line':
      getLineBar('line')
      break
    case 'bar':
      getLineBar('bar')
      break
    case 'bar_line':
      getLineBar('bar_line')
      break
    case 'multiaxialLine':
      getMultiaxialLineBar('line')
      break
    case 'multiaxialBar':
      getMultiaxialLineBar('bar')
      break
    case 'multiaxial2':
      getMultiaxialLineBar2()
      break
    case 'radar':
      getRadar()
      break
    case 'funnel':
      getFunnel()
      break
    case 'scatter':
      getScatter()
      break
    case 'gauge':
      getGauge()
      break
    case 'bubble':
      getBubble()
      break
    case 'map':
      getMap()
      break
    case 'sankey':
      getSankey()
      break
    case 'table':
      getTable()
      break
    case 'table_more':
      gettable_more()
      break
  }

  function sortNumber(a, b) {
    return a - b
  }
  function median(arr) {
    arr.sort(sortNumber)
    //求中位数
    if (arr.length % 2 == 0) {
      return (arr[arr.length / 2 - 1] + arr[arr.length / 2]) / 2
    } else {
      return arr[Math.floor(arr.length / 2)]
    }
  }

  function getCard() {
    const meaalias = measureColumns[0].alias
    const groupalias = groups.length > 0 ? groups[0].alias : ''
    let columns = copyColumns
    let rows = copyRows[0]
    let rows0 = copyRows[0]
    option.value = ''
    option.numberFontSize = styleSet ? styleSet.numberFontSize : 'auto'
    option.unitName = styleSet ? styleSet.unitName || '' : ''
    option.measureName = styleSet ? (styleSet.measureName = styleSet.measureName || measureColumns[0].name) : ''
    option.showMeasure = styleSet ? styleSet.showMeasure : ''
    option.measureNameFontSize = styleSet ? styleSet.measureNameFontSize : ''
    option.cardConrastdata = []
    option.value = numUnit(rows0[meaalias])

    function numUnit(num) {
      const propItem = propSetprops && propSetprops[0]
      const fmt = propItem?.format ?? null
      let denominator = 1
      if (styleSet && styleSet.numberunit == 'tenthousand') {
        option.numberunit = '万'
        denominator = 10000
      } else if (styleSet && styleSet.numberunit == 'million') {
        option.numberunit = '百万'
        denominator = 1000000
      } else if (styleSet && styleSet.numberunit == 'billion') {
        option.numberunit = '亿'
        denominator = 100000000
      } else if (styleSet && styleSet.numberunit == 'thousand') {
        option.numberunit = 'K'
        denominator = 1000
      } else if (styleSet && styleSet.numberunit == 'trillion') {
        option.numberunit = 'M'
        denominator = 1000000
      } else {
        option.numberunit = ''
      }
      const fmtendnum = parseFloat(num / denominator)
      const endnum = parseFloat((num / denominator).toFixed(2))
      return fmt ? _this.$commonJs.propformat(fmtendnum, fmt) : endnum
    }
    let rateColumns = columns.filter(x => {
      return x.usage == 'Rate'
    })
    option.cardConrastdata = rateColumns.map(c => {
      let tit = ''
      if (c.alias.includes('_contrastRate')) {
        tit = '对比'
      } else if (c.alias.includes('_lastRate')) {
        tit = '同比'
      } else if (c.alias.includes('_circleRate')) {
        tit = '环比'
      }
      return {
        title: tit,
        value: rows0[c.alias],
      }
    })
    option.arr = copyRows.map(x => {
      let list = {
        label: groupalias ? x[groupalias] : 'g0',
        value: numUnit(x[meaalias]),
      }
      list.cardConrastdata = rateColumns.map(c => {
        let tit = ''
        if (c.alias.includes('_contrastRate')) {
          tit = '对比'
        } else if (c.alias.includes('_lastRate')) {
          tit = '同比'
        } else if (c.alias.includes('_circleRate')) {
          tit = '环比'
        }
        return {
          title: tit,
          value: x[c.alias],
        }
      })
      return list
    })
  }

  function getPie() {
    let config = {
      legend: [],
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
    }
    if (typeof styleSet.showNumber == 'undefined') {
      styleSet.showNumber = true
    }
    if (typeof styleSet.enablePencentage == 'undefined') {
      styleSet.enablePencentage = true
    }

    config.legend = hasGroup ? firstGroupList : ['总体']
    config.legend = config.legend.length > 0 ? config.legend : allprops[0].title
    let ser = {}
    copyRows.forEach(x => {
      ser = {
        name: x.group_key ? x.group_key : allprops[0].title,
        value: x[allprops[0].alias],
      }
      config.seriesdata.push(ser)
    })
    option = drows.drowPieV2(_this, config)
  }

  function getLineBar(isline) {
    let config = {
      isline: isline,
      legend: [],
      seriesdata: [],
      xAxisdata: [],
      yAxis: [{ type: 'value' }],
      setting: setting,
      styleSet: styleSet,
      max: '',
      max_w: '',
      markLineNum: '',
    }

    let ishor = styleSet.direction ? styleSet.direction == 'horizontal' : styleSet.enableHorizontal
    let isHorizontal = ishor && isline == 'bar' ? true : false //是否横向
    let isStack = styleSet.enableStack && isline === 'bar' //是否堆积
    let areaVal = 0
    let smooth = styleSet.type == 'areaCurve' || styleSet.type == 'curve' || styleSet.lineType == 'areaCurve' || styleSet.lineType == 'curve' ? true : false
    firstGroupList = isHorizontal ? firstGroupList.reverse() : firstGroupList
    copyRows = isHorizontal ? copyRows.reverse() : copyRows

    if (styleSet.showYAxisTitle) {
      config.yAxisname = styleSet.yAxisTitle ? styleSet.yAxisTitle : measureColumns[0].title
      // config.yAxisname = styleSet.yAxisUnit ? config.yAxisname + '(' + styleSet.yAxisUnit + ')' : config.yAxisname
    }
    if (styleSet.showXAxisTitle) {
      config.xAxisname = styleSet.xAxisTitle ? styleSet.xAxisTitle : groups && groups.length > 0 ? groups[0].title : ''
    }
    if (styleSet.direction == 'horizontal') {
      var x = config.yAxisname
      config.yAxisname = config.xAxisname
      config.xAxisname = x
    } else {
      //竖向获取最大值
      let maxNum = 0
      chartprops.forEach(c => {
        copyRows.forEach(row => {
          var num = row[c.alias]
          if (maxNum < num) {
            maxNum = num
          }
        })
        config.max = maxNum
      })
      let maxval = _this.$commonJs.formatnum(basicInfo.project.axisUnit, config.max)
      config.max_w = _this.$commonJs.getWordsWidth(maxval)
    }
    let areaValtype = styleSet.type || styleSet.lineType
    switch (areaValtype) {
      case 'line':
        areaVal = 0
        break
      case 'areaLine':
        areaVal = 0.2
        break
      case 'curve':
        areaVal = 0
        break
      case 'areaCurve':
        areaVal = 0.2
        break
    }
    let numArr = []
    copyRows.map(c => {
      chartprops.map(p => {
        if (typeof c[p.alias] == 'number') {
          numArr.push(c[p.alias])
        }
      })
    })
    config.max = Math.max.apply(null, numArr)

    config.xAxisdata = hasGroup ? firstGroupList : ['总体']
    //关联分析 柱图特殊处理
    if (opts.analysisType == 'association') {
      config.xAxisdata = copyRows.map(x => {
        return x.itemset_a + ',' + x.itemset_b
      })
      let filtercolumns = opts.filterSet.columns.filter(x => {
        return x.selected
      })
      filtercolumns.map(x => {
        config.legend.push(x.text)
        let ser = {
          name: x.text,
          type: isline,
          barMaxWidth: 30,
          label: {
            show: styleSet.enableLable,
            position: isStack ? '' : isHorizontal ? 'right' : 'top',
            color: labelColor,
            formatter: function(v) {
              return _this.$commonJs.formatnum(basicInfo.project.axisUnit, v.data)
            },
          },
          data: [],
          code: x.code,
          displayName: x.text,
          measure: x.text,
        }
        copyRows.forEach(d => {
          ser.data.push(d[x.code])
        })
        config.seriesdata.push(ser)
      })
    } //留存 特殊处理
    else if (opts.analysisType == 'retention') {
      let durationList = []
      durationList = chartprops.filter(x => {
        return x.alias != 'person_number_all'
      })
      chartprops = chartprops.filter(x => {
        return x.alias == 'person_number_all'
      })
      let xAxisList = durationList.map(x => {
        return x.title
      })
      config.xAxisdata = hasGroup ? xAxisList : ['总体']
      otherGroupList.map(g => {
        chartprops.map(e => {
          let leg = g ? g + '-' + e.title : e.title
          let ser = {
            name: leg,
            type: isline,
            lineStyle: {
              type: getLineStyle(e),
            },
            barMaxWidth: 30,
            data: [],
            stack: isStack ? `堆积-${e.title}` : '',
            label: {
              show: styleSet.enableLable,
              position: isStack ? '' : isHorizontal ? 'right' : 'top',
              color: labelColor,
              formatter: function(v) {
                return _this.$commonJs.formatnum(basicInfo.project.axisUnit, v.data)
              },
            },
            smooth: smooth,
            areaStyle: {
              opacity: areaVal,
            },
            code: getMeasureCode(e),
            displayName: (g ? g : e.title) + getMeasureTail(e),
            measure: g ? getMeasureName(e) : '',
          }
          if (firstGroupList.length > 0) {
            firstGroupList.map(xix => {
              let sult = copyRows.find(r => {
                return g ? xix == r[groups[0].alias] && r.otherGroupname == g : xix == r[groups[0].alias]
              })
              //兼容留存分析
              config.legend.push(xix)
              let newser = JSON.parse(JSON.stringify(ser))
              newser.name = xix
              durationList.forEach(dur => {
                let duralias = dur.alias
                let durval = sult[duralias]
                //留存率
                if (setting.styleSet.enableRate) {
                  let aliasarr = dur.alias.split('_')
                  duralias = 'rate_' + aliasarr[aliasarr.length - 1]
                  durval = sult[duralias]
                  durval = durval ? parseFloat(durval.replace('%', '')) : 0

                  newser.label.formatter = function(v) {
                    return v.data.toFixed(2) + '%'
                  }
                }
                let val = sult ? durval || 0 : 0
                newser.data.push(val)
              })
              config.seriesdata.push(newser)
            })
          } else {
            let val = copyRows[0] ? copyRows[0][e.alias] : 0
            ser.data.push(val)
            config.seriesdata.push(ser)
          }
        })
      })
    } else {
      otherGroupList.forEach(g => {
        chartprops.forEach(e => {
          // 'bar_line' 是 柱图+线图 图表
          let lineType = isline == 'bar_line' ? (styleSet.lineprops.includes(e.alias) ? 'line' : 'bar') : isline
          let leg = g ? g + '-' + e.title : e.title
          config.legend.push(leg)
          let ser = {
            name: leg,
            type: lineType,
            lineStyle: {
              type: getLineStyle(e),
            },
            barMaxWidth: 30,
            data: [],
            stack: isStack && lineType === 'bar' ? `堆积-${e.title}` : '',
            label: {
              show: styleSet.enableLable,
              position: isStack && lineType === 'bar' ? '' : isHorizontal ? 'right' : 'top',
              color: labelColor,
              formatter: function(v) {
                return _this.$commonJs.formatnum(basicInfo.project.axisUnit, v.data)
              },
            },
            smooth: smooth,
            areaStyle: {
              opacity: areaVal,
            },
            code: getMeasureCode(e),
            displayName: (g ? g : e.title) + getMeasureTail(e),
            measure: g ? getMeasureName(e) : '',
          }
          if (firstGroupList.length > 0) {
            firstGroupList.map(xix => {
              let sult = copyRows.find(r => {
                return g ? xix == r[groups[0].alias] && r.otherGroupname == g : xix == r[groups[0].alias]
              })
              let val = sult ? sult[e.alias] : 0
              ser.data.push(val)
            })
          } else {
            let val = copyRows[0] ? copyRows[0][e.alias] : 0
            ser.data.push(val)
          }

          if (lineType == 'line') {
            ser.symbolSize = styleSet.showDataPoint ? 10 : 2
          }
          config.seriesdata.push(ser)
        })
      })
    }

    //辅助线
    _this.$commonJs2.initMarkLine(_this, chart.chartColorV2, config, copyRows, contrastLabels)
    option = drows.drowLineV2(_this, config)
  }
  function getMultiaxialLineBar(isline) {
    let config = {
      legend: [],
      xAxisdata: [],
      yAxis: [],
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
      maxarrlen: [], //多轴图每个轴的 单位最长的集合
    }
    config.xAxisdata = hasGroup ? firstGroupList : ['总体']

    otherGroupList.map(g => {
      chartprops.map(e => {
        let leg = g ? g + '-' + e.title : e.title
        config.legend.push(leg)
        let yAxisIndex = 0

        measureColumns.forEach((y, yidx) => {
          yAxisIndex = e.title == y.title ? yidx : yAxisIndex
        })
        let ser = {
          name: leg,
          type: isline,
          lineStyle: {
            type: getLineStyle(e),
          },
          barMaxWidth: 30,
          data: [],
          yAxisIndex: yAxisIndex,
          label: {
            show: styleSet.enableLable,
            color: labelColor,
            position: 'top',
            formatter: function(v) {
              return _this.$commonJs.formatnum(basicInfo.project.axisUnit, v.value)
            },
          },

          code: getMeasureCode(e),
          displayName: (g ? g : e.title) + getMeasureTail(e),
          measure: g ? getMeasureName(e) : '',
        }
        if (!hasGroup) {
          let val = copyRows[0] ? copyRows[0][e.alias] : 0
          ser.data.push(val)
        } else {
          config.xAxisdata.map(xix => {
            let sult = copyRows.filter(r => {
              // return xix == r[groups[0].alias] && r.otherGroupname == g
              return g ? xix == r[groups[0].alias] && r.otherGroupname == g : xix == r[groups[0].alias]
              // return g ? xix == r[groups[0].alias] && (r[groups[0].code] == g || r[groups[1].code] == g || r[groups[1].code+'_dim'] == g) : xix == r[groups[0].alias]
            })
            let val = sult[0] ? sult[0][e.alias] : 0
            ser.data.push(val)
          })
        }
        config.seriesdata.push(ser)
      })
    })
    measureColumns.map((x, i) => {
      let max = 0
      let numArr = []
      copyRows.map(c => {
        chartprops.map(p => {
          if (p.title == x.title) {
            numArr.push(c[p.alias])
          }
        })
      })
      max = Math.max.apply(null, numArr)
      let max_w = _this.$commonJs.getWordsWidth(_this.$commonJs.formatnum(basicInfo.project.axisUnit, max))

      config.maxarrlen.push(max_w)
      let yAx = {
        type: 'value',
        name: x.title,
        // min:0,
        // max:max,
        nameLocation: 'middle',
        nameRotate: 90,
        nameGap: max_w - 5,
        position: i % 2 == 0 ? 'left' : 'right',
        offset: _this.$commonJs.getoffset(i, config.maxarrlen),
        nameTextStyle: {
          color: labelColor,
        },
        axisLabel: {
          color: labelColor,
          formatter: function(v) {
            return _this.$commonJs.processAxisunit(max, v, setting)
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: styleSet.showGridLine,
          lineStyle: {
            type: 'dotted',
            color: basicInfo.project.theme == 0 ? '#E9ECEF' : '#555',
          },
        },
      }
      config.yAxis.push(yAx)
    })
    option = drows.drowMultiaxialV2(_this, config)
  }
  function getMultiaxialLineBar2() {
    let config = {
      legend: [],
      xAxisdata: [],
      yAxis: [],
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
      maxarrlen: [], //多轴图每个轴的 单位最长的集合
    }

    let contrastSetting = query[0].displaySettingGroup

    config.xAxisdata = hasGroup ? firstGroupList : ['总体']

    let isTwo = measureColumns.length == 1 ? chartprops : measureColumns

    otherGroupList.map(g => {
      chartprops.map((e, eidx) => {
        let sett = eidx % 2 == 0 ? contrastSetting.left : contrastSetting.right
        e.title = e.alias.includes('_contrast') ? e.title + '(对比值)' : e.title
        let leg = g ? g + '-' + e.title : e.title
        config.legend.push(leg)

        let ser = {
          name: leg,
          type: sett.type == 'areaCurve' || sett.type == 'areaLine' ? 'line' : sett.type,
          lineStyle: {
            type: getLineStyle(e),
          },
          barMaxWidth: 30,
          data: [],
          yAxisIndex: contrastSetting.left.axisNum == 'single' ? '0' : eidx,
          label: {
            show: sett.enableLable,
            color: labelColor,
            position: 'top',
            formatter: function(v) {
              return _this.$commonJs.formatnum(basicInfo.project.axisUnit, v.value)
            },
          },

          code: getMeasureCode(e),
          displayName: g ? g : e.title,
          measure: g ? getMeasureName(e) : '',
        }
        if (!hasGroup) {
          let val = copyRows[0] ? copyRows[0][e.alias] : 0
          ser.data.push(val)
        } else {
          config.xAxisdata.map(xix => {
            let sult = copyRows.filter(r => {
              // return xix == r[groups[0].alias] && r.otherGroupname == g
              return g ? xix == r[groups[0].alias] && r.otherGroupname == g : xix == r[groups[0].alias]
            })
            let val = sult[0] ? sult[0][e.alias] : 0
            ser.data.push(val)
          })
        }
        config.seriesdata.push(ser)
      })
    })

    //兼容单轴图的最大值
    let allnumArr = []
    if (contrastSetting.left.axisNum == 'single') {
      isTwo.forEach(x => {
        copyRows.forEach(c => {
          chartprops.map(p => {
            if (p.title.includes(x.title)) {
              allnumArr.push(c[p.alias])
            }
          })
        })
      })
    }

    isTwo.map((x, i) => {
      let max = 0
      let numArr = []
      copyRows.map(c => {
        chartprops.map(p => {
          if (p.title == x.title) {
            numArr.push(c[p.alias])
          }
        })
      })
      max = contrastSetting.left.axisNum == 'single' ? Math.max.apply(null, allnumArr) : Math.max.apply(null, numArr)
      let max_w = _this.$commonJs.getyAxixWidth(_this.$commonJs.formatnum(basicInfo.project.axisUnit, max))
      max_w = max_w < 35 ? max_w + 10 : max_w

      config.maxarrlen.push(max_w)

      const yAx = {
        type: 'value',
        name: x.title,
        nameLocation: 'middle',
        nameRotate: 90,
        nameGap: max_w - 5,
        position: i % 2 == 0 ? 'left' : 'right',
        offset: _this.$commonJs.getoffset(i, config.maxarrlen),
        nameTextStyle: {
          color: labelColor,
        },
        axisLabel: {
          color: 'labelColor',
          formatter: function(v) {
            return _this.$commonJs.processAxisunit(max, v, setting)
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            type: 'dotted',
            color: basicInfo.project.theme == 0 ? '#E9ECEF' : '#555',
          },
        },
      }
      config.yAxis.push(yAx)
    })
    if (contrastSetting.left.axisNum == 'single') {
      config.yAxis.splice(1, 1)
      config.maxarrlen[1] = 0
    }
    option = drows.drowMultiaxialV2(_this, config)
  }
  function getRadar() {
    let config = {
      legend: [],
      seriesdata: [],
      indicator: [],
      setting: setting,
      styleSet: styleSet,
    }
    config.legend = hasGroup ? firstGroupList : ['总体']
    measureColumns.map(x => {
      let max = 0
      let numArr = []
      copyRows.map(c => {
        chartprops.map(p => {
          if (p.title.includes(x.title)) {
            numArr.push(c[p.alias])
          }
        })
      })
      max = Math.max.apply(null, numArr)
      let cator = {
        name: x.title,
        max: max * 1.2,
      }
      config.indicator.push(cator)
    })

    copyRows.map((x, idx) => {
      getSer(x, idx)
      tails.map(t => {
        let contrastProp = chartprops.filter(c => {
          return c['alias'].includes(t)
        })
        if (contrastProp.length > 0) {
          getSer(x, idx, t)
        }
      })
    })
    function getSer(x, idx, t) {
      let oname = x.otherGroupname ? x.otherGroupname : firstGroupList[idx]
      let ser = {
        name: hasGroup ? (t ? oname + ' - ' + contrastLabels[t] : oname) : '总体',
        lineStyle: {
          // type: lineStyle,
        },
        value: [],
      }
      measureColumns.map(c => {
        let alias = t ? c.alias + t : c.alias
        ser.value.push(x[alias])
      })
      config.seriesdata.push(ser)
    }

    option = drows.drowRadarV2(_this, config)
  }
  function getFunnel() {
    let config = {
      legend: [],
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
    }
    let steps = data.columns.filter(x => {
      return x.usage == 'Step'
    })
    config.legend = steps.map(function(x) {
      return x.title
    })

    let ser = {
      type: 'funnel',
      data: [],
      top: 26,
      bottom: 20,
    }
    data.columns.forEach(x => {
      if (x.alias != 'OverallConversionRate' && x.usage != 'Group') {
        let list = {
          name: x.title,
          value: data.rows[0][x.alias],
        }
        ser.data.push(list)
      }
    })

    config.seriesdata.push(ser)

    //配置属性
    option = drows.drowFunnelV2(_this, config)

    let group = data.columns.find(x => {
      return x.usage == 'Group'
    })
    option.funnelTips = {
      name: group ? group.title : '总体',
    }
    option.columns = data.columns
    option.total = data.rows.map(x => {
      let rate = {
        ...x,
        title: group ? x.g0 : '全部',
        value: x.OverallConversionRate,
      }
      return rate
    })
  }
  function getScatter() {
    let config = {
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
      xAxisname: '',
      yAxisname: '',
      max_w: '',
      x_max: '',
      y_max: '',
    }
    config.xAxisname = styleSet.xAxisTitle ? styleSet.xAxisTitle : measureColumns[0].title
    config.yAxisname = styleSet.yAxisTitle ? styleSet.yAxisTitle : measureColumns[1].title
    // config.yAxisname = styleSet.yAxisUnit ? config.yAxisname + '(' + styleSet.yAxisUnit + ')' : config.yAxisname
    if (typeof styleSet.xAxisTitle == 'undefined' && typeof styleSet.showXAxisTitle == 'undefined') {
      styleSet.showXAxisTitle = true
    }
    if (typeof styleSet.yAxisTitle == 'undefined' && typeof styleSet.showYAxisTitle == 'undefined') {
      styleSet.showYAxisTitle = true
    }
    let x_numArr = []
    let y_numArr = []
    copyRows.forEach(c => {
      x_numArr.push(c[chartprops[0].alias])
      y_numArr.push(c[chartprops[1].alias])
    })
    config.x_max = Math.max.apply(null, x_numArr)
    config.y_max = Math.max.apply(null, y_numArr)
    config.max_w = _this.$commonJs.getWordsWidth(_this.$commonJs.formatnum(basicInfo.project.axisUnit, config.y_max))

    copyRows.map(x => {
      if (measureColumns) {
        let ser = {
          name: x.group_key,
          type: 'scatter',
          data: [],
          label: {
            show: styleSet.enableLable,
            offset: [0, -10],
            color: labelColor,
            formatter: function(v) {
              return x.group_key
            },
          },
          columns: measureColumns,
        }
        let scatArr = []
        measureColumns.map(c => {
          scatArr.push(x[c.alias])
        })
        ser.data.push(scatArr)
        config.seriesdata.push(ser)
      }
      tails.forEach(t => {
        let contrastProp = chartprops.filter(c => {
          return c['alias'].includes(t)
        })
        if (contrastProp.length > 0) {
          let ser = {
            type: 'scatter',
            data: [],
            label: {
              show: styleSet.enableLable,
              offset: [0, -10],
              color: '#444',
              formatter: function() {
                return x.group_key
              },
            },
          }
          ser.name = x.group_key + '-' + contrastLabels[t]
          ser.columns = contrastProp
          ser.label.formatter = function() {
            return x.group_key + '-' + contrastLabels[t]
          }
          let scatArr = []
          contrastProp.forEach(c => {
            scatArr.push(x[c.alias])
          })
          ser.data.push(scatArr)
          config.seriesdata.push(ser)
        }
      })
    })

    //辅助线
    if (config.seriesdata[0]) {
      config.seriesdata[0].markLine = {
        silent: false,
        data: [],
      }
    }
    var markLineDataItem = {
      label: {
        show: true,
        position: 'end',
        color: labelColor,
        formatter: function(v) {
          return _this.$commonJs.formatnum(basicInfo.project.axisUnit, v.value)
        },
      },
      lineStyle: {
        normal: {
          color: chart.chartColorV2[0],
          width: 1,
          type: 'dashed',
        },
      },
    }
    if (styleSet.subline == 'fixed') {
      if (num_pattern.test(styleSet.xAxisValue)) {
        let item = _this.$lo_.cloneDeep(markLineDataItem)
        item.xAxis = styleSet.xAxisValue
        item.name = '固定值'
        config.seriesdata[0].markLine.data.push(item)
      }
      if (num_pattern.test(styleSet.yAxisValue)) {
        let item = _this.$lo_.cloneDeep(markLineDataItem)
        item.yAxis = styleSet.yAxisValue
        item.name = '固定值'
        config.seriesdata[0].markLine.data.push(item)
      }
    }
    if (styleSet.subline == 'average') {
      var num = 0
      var name = ''
      var setMarkLine = (alias, aggregation) => {
        var values = copyRows.map(x => x[alias])
        if (aggregation == 'max') {
          num = _this.$lo_.max(values)
          name = '最大值'
        } else if (aggregation == 'min') {
          num = _this.$lo_.min(values)
          name = '最小值'
        } else if (aggregation == 'avg') {
          if (values.length > 0) {
            num = (_this.$lo_.sum(values) / values.length).toFixed(2)
          }
          name = '平均值'
        } else {
          num = median(values)
          name = '中位数'
        }
      }

      setMarkLine(allprops[0].alias, styleSet.xAxisSublineAggregation)
      let item = _this.$lo_.cloneDeep(markLineDataItem)
      item.xAxis = num
      item.name = name
      config.seriesdata[0].markLine.data.push(item)

      setMarkLine(allprops[1].alias, styleSet.yAxisSublineAggregation)
      item = _this.$lo_.cloneDeep(markLineDataItem)
      item.yAxis = num
      item.name = name
      config.seriesdata[0].markLine.data.push(item)
    }

    option = drows.drowScatterV2(_this, config)
  }
  function getGauge() {
    let config = {
      legend: [],
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
    }

    let count = copyRows[0][measureColumns[0].alias]
    //目标值 暂时写一个
    let targetname = styleSet.targetname
    let gaugeTarget = styleSet.target
    let tagetvalue = ''
    tagetvalue = gaugeTarget ? ((count * 100) / gaugeTarget).toFixed(2) : count > 0 ? 100 : 0
    let ser = {
      radius: '95%',
      title: {
        show: styleSet.tag,
        offsetCenter: [0, '20%'],
        fontSize: 12,
      },
      type: 'gauge',
      detail: {
        formatter: function(value) {
          return targetname == 'percent' ? value + '%' : count
        },
      },
      data: [
        {
          value: tagetvalue,
          name: measureColumns[0].title,
        },
      ],
    }
    config.seriesdata.push(ser)

    option = drows.drowGaugeV2(_this, config)
  }
  function getBubble() {
    let config = {
      seriesdata: [],
      setting: setting,
      styleSet: styleSet,
      xAxisname: '',
      yAxisname: '',
      max_w: '',
      max: '',
      x_max: '',
      y_max: '',
    }
    config.xAxisname = styleSet.xAxisTitle ? styleSet.xAxisTitle : measureColumns[0].title
    config.yAxisname = styleSet.yAxisTitle ? styleSet.yAxisTitle : measureColumns[1].title
    // config.yAxisname = styleSet.yAxisUnit ? config.yAxisname + '(' + styleSet.yAxisUnit + ')' : config.yAxisname

    // let max = 0
    let x_numArr = []
    let y_numArr = []
    let maxSymbol = 0
    // if (styleSet.bubbleSize) {
    //   maxSymbol = styleSet.bubbleSize
    // }
    copyRows.forEach(c => {
      if (c[measureColumns[2].alias] > maxSymbol) maxSymbol = c[measureColumns[2].alias]
      x_numArr.push(c[chartprops[0].alias])
      y_numArr.push(c[chartprops[1].alias])
    })

    config.x_max = Math.max.apply(null, x_numArr)
    config.y_max = Math.max.apply(null, y_numArr)
    config.max_w = _this.$commonJs.getWordsWidth(_this.$commonJs.formatnum(basicInfo.project.axisUnit, config.y_max))

    copyRows.map(x => {
      getSer(x)

      tails.map(t => {
        let contrastProp = chartprops.filter(c => {
          return c['alias'].includes(t)
        })
        if (contrastProp.length > 0) {
          getSer(x, t)
        }
      })
    })
    function getSer(x, t) {
      let ser = {
        name: x.group_key,
        type: 'scatter',
        symbolSize: function(v) {
          let ret = (v[2] * 50) / maxSymbol > 8 ? (v[2] * 50) / maxSymbol : 8
          return ret > 50 ? 50 : ret
        },
        data: [],
        label: {
          show: styleSet.enableLable,
          offset: [0, -10],
          color: labelColor,
          formatter: function(v) {
            return x.group_key
          },
        },
        columns: measureColumns,
      }
      let ser_data = []
      measureColumns.map(c => {
        let alias = t ? c.alias + t : c.alias
        ser_data.push(x[alias])
      })

      ser_data.push(x.group_key)
      ser.data.push(ser_data)

      config.seriesdata.push(ser)
    }
    if (config.seriesdata[0]) {
      config.seriesdata[0].markLine = {
        silent: false,
        data: [],
      }
    }
    var markLineDataItem = {
      label: {
        show: true,
        position: 'end',
        color: labelColor,
        formatter: function(v) {
          return _this.$commonJs.formatnum(basicInfo.project.axisUnit, v.value)
        },
      },
      lineStyle: {
        normal: {
          color: chart.chartColorV2[0],
          width: 1,
          type: 'dashed',
        },
      },
    }
    if (styleSet.subline == 'fixed') {
      if (num_pattern.test(styleSet.xAxisValue)) {
        let item = _this.$lo_.cloneDeep(markLineDataItem)
        item.xAxis = styleSet.xAxisValue
        item.name = '固定值'
        config.seriesdata[0].markLine.data.push(item)
      }
      if (num_pattern.test(styleSet.yAxisValue)) {
        let item = _this.$lo_.cloneDeep(markLineDataItem)
        item.yAxis = styleSet.yAxisValue
        item.name = '固定值'
        config.seriesdata[0].markLine.data.push(item)
      }
    }
    if (styleSet.subline == 'average') {
      var num = 0
      var name = ''
      var setMarkLine = (alias, aggregation) => {
        var values = copyRows.map(x => x[alias])
        if (aggregation == 'max') {
          num = _this.$lo_.max(values)
          name = '最大值'
        } else if (aggregation == 'min') {
          num = _this.$lo_.min(values)
          name = '最小值'
        } else if (aggregation == 'avg') {
          if (values.length > 0) {
            num = (_this.$lo_.sum(values) / values.length).toFixed(2)
          }
          name = '平均值'
        } else {
          num = median(values)
          name = '中位数'
        }
      }
      setMarkLine(styleSet.xAxisSublineField, styleSet.xAxisSublineAggregation)
      let item = _this.$lo_.cloneDeep(markLineDataItem)
      item.xAxis = num
      item.name = name
      config.seriesdata[0].markLine.data.push(item)

      setMarkLine(styleSet.yAxisSublineField, styleSet.yAxisSublineAggregation)
      item = _this.$lo_.cloneDeep(markLineDataItem)
      item.yAxis = num
      item.name = name
      config.seriesdata[0].markLine.data.push(item)
    }

    option = drows.drowScatterV2(_this, config)
  }
  function getMap() {
    option.mapConfig = getmapConfig()
  }
  function getColor(value, nums, colors) {
    let color = ''
    nums.forEach((x, i) => {
      if (i < 9 && value > x[0] && value <= x[1]) {
        color = colors[i]
      }
      if (i == 9 && value > x[0]) {
        color = colors[i]
      }
    })
    return color
  }
  function getmapConfig() {
    const colors = ['#EFE387', '#DEDB99', '#B9D896', '#B6D5F4', '#C2B5FF', '#FFA9FF', '#F2ACAC', '#C894E3 ', '#8282FF', '#0070FF']
    let measure = copyColumns.filter(x => {
      return x.usage == 'Measure'
    })[0]
    let max = ''
    copyRows.forEach(x => {
      max = x[measure.alias] > max ? x[measure.alias] : max
    })
    max = styleSet.enableMax == 'auto' ? max : styleSet.enableFixed ? styleSet.enableFixed : max
    let nums = Array.from(new Array(10), (item, k) => [parseFloat(((max * k) / 10).toFixed(2)), parseFloat(((max * (k + 1)) / 10).toFixed(2))])

    let shopData = copyRows.map(x => {
      let mapAreaId = hotmapdata.mappings[x.shop__shop_id_dim]
      let shop = {
        bizId: x.shop__shop_id_dim,
        name: x.shop__shop_name_dim,
        mapAreaId: mapAreaId ? mapAreaId[0] : '00',
        Fill: getColor(x[measure.alias], nums, colors),
        toastHtml: `<dl>
        <dd>${x.shop__shop_name_dim}：${x[measure.alias]} ${styleSet.enableUnit ? '(' + styleSet.enableUnit + ')' : ''}</dd>
          </dl>`,
      }
      return shop
    })
    let markData = copyRows.map(x => {
      let mapAreaId = hotmapdata.mappings[x.shop__shop_id_dim]
      let mark = {
        bizId: x.shop__shop_id_dim,
        name: styleSet.enableLable ? x.shop__shop_name_dim : '',
        mapAreaId: mapAreaId ? mapAreaId[0] : '00',
      }
      return mark
    })
    let floorMap = hotmapdata.floorMap.replace('↵', '')
    let labelData = nums.map((x, i) => {
      let label = {
        labelName: x[0],
        bgColor: colors[i],
      }
      return label
    })
    let mapConfig = {
      maxScale: 20,
      zoomScale: 0.2,
      txtGap: 10,
      mapGap: 150,
      defaultFill: '#eeeeee',
      gTxtColor: '#666666',
      labelData: labelData,
      shopData: shopData,
      markData: markData,
      svg: floorMap,
      max: max,
      floor: hotmapdata.floorName,
    }
    return mapConfig
  }
  function gettable_more() {
    option.activeTabName = 'tab0'
    option.data = gettabledata()
  }
  function getTable() {
    if (tbdata.thead[0] && tbdata.thead[0].label == '总体' && tbdata.tbody[0]) {
      tbdata.tbody[0][tbdata.thead[0].prop] = '总体'
    }
    option = tbdata
    option.opts = opts
    option.field = {}
    option.thead.forEach(x => {
      option.field[x.label] = x.prop
    })
  }

  function getSankey() {
    let config = {
      legend: [],
      setting: query.displaySettings,
      direction: query.pathType,
      data: [],
      links: [],
      allnum: 0,
    }

    function getTail(el, i, position) {
      let name = el.path.split('-->')[i]
      const name_raw = el.path_orig.split('<:>')[i]
      var leftTail = ' '
      var rightTail = '  '
      var middleTail = ''
      if (config.direction == 'both' && name_raw.indexOf('[*]') > 0) {
        //双向时标记的为中间节点
        name = name + middleTail
      } else {
        name = name + (position == 'l' ? leftTail : rightTail)
      }
      return name
    }

    let newSet = new Set()
    let newList = []
    let filterdata = copyRows.filter(el => {
      return el.path.indexOf('-->') > 0
    })
    filterdata.forEach(el => {
      newList.push({ name: getTail(el, 0, 'l'), label: { position: 'right' } })
      newList.push({ name: getTail(el, 1, 'r'), label: { position: 'left' } })
    })
    newList.forEach(el => {
      let jsonel = el.name
      let setLen = [...newSet].length
      newSet.add(jsonel)
      if ([...newSet].length > setLen) {
        config.data.push(el)
      }
    })

    // 格式化data
    let allnum = 0
    copyRows.map(el => {
      el.path.indexOf('-->') > -1 ? (allnum += el.user_fixed_user_mid_distinct) : allnum
    })
    config.allnum = allnum
    config.links = copyRows
      .filter(el => {
        let isPath = el.path.indexOf('-->') > 0
        if (config.direction == 'both') {
          let hide = el.path.split('-->')[0] == el.path.split('-->')[1]
          return isPath && !hide
        } else {
          return isPath
        }
      })
      .map(el => {
        return {
          source: getTail(el, 0, 'l'),
          target: getTail(el, 1, 'r'),
          value: el.user_fixed_user_mid_distinct,
        }
      })

    //配置属性
    option = drows1.drowSankey(_this, config)
  }

  //获取表格数据
  function gettabledata() {
    let tabledata = []
    let first = {
      tabname: '总览',
      tableHead: data.columns,
      opt: data.rows,
    }
    tabledata.push(first)

    data.funnels.forEach(x => {
      let list = {
        tabname: x.step,
        tableHead: x.columns,
        opt: x.rows,
      }
      tabledata.push(list)
    })
    return tabledata
  }

  //获取不同事件
  function getMeasureCode(e) {
    let code = ''
    measureColumns.forEach(p => {
      if (e.alias.includes(p.alias)) {
        code = p.alias
      }
    })
    return code
  }
  function getMeasureName(e) {
    let name = ''
    measureColumns.forEach(p => {
      if (e.alias.includes(p.alias)) {
        name = p.title
      }
    })
    return name
  }

  function getMeasureTail(e) {
    let tail = ''
    if (e.alias.includes('_contrast')) {
      tail = ' - 对比'
    }
    if (e.alias.includes('_last')) {
      tail = ' - 同比'
    }
    if (e.alias.includes('_circle')) {
      tail = ' - 环比'
    }
    return tail
  }

  function getLineStyle(e) {
    let lineType = 'solid'
    if (e.alias.includes('_contrast')) {
      lineType = 'dotted'
    }
    if (e.alias.includes('_last')) {
      lineType = 'dotted'
    }
    if (e.alias.includes('_circle')) {
      lineType = 'dashad'
    }
    return lineType
  }

  return option
}
