import initMarkV1 from '@/script/bookmarkV1/initMarkV1'
import initMarkV2 from '@/script/bookmarkV2/initMarkV2'
import cardinitMark from '@/views/dashboard/carddashboard/cardinitMark'
let dashbdjs = {
  getChartData: (_this, BI_config, v, el, i, scheduler, ismobile) => {
    //卡片根据 1.0 还是 2.0 来区分处理
    let chart = {}
    if (el.version == '2.0') {
      if (el.category === 'combination') {
        chart = cardinitMark.init(_this, BI_config, v, el, i)
      } else {
        chart = initMarkV2.init(_this, BI_config, v, el, i)
      }
    } else {
      chart = initMarkV1.init(_this, BI_config, v, el, i)
    }

    if (ismobile) {
      chart.ismobile = true
    }
    _this.chartdatacache.push(chart)
    _this.chartData.push(chart)

    if (_this.position.length === _this.bookmarks.length) {
      _this.gridData = _this.position
      _this.copygridData = _this.position
      _this.chartData = _this.chartdatacache
    }
    if (el.version == '2.0') {
      if (el.canVisit) {
        if (el.category === 'combination') {
          _this.copyChartData[i].category = el.category
          _this.copyChartData[i].bookmarkid = el.id
          cardinitMark.searchRequest(_this, i, scheduler, 'init')
        } else {
          initMarkV2.searchRequest2(_this, i, scheduler)
        }
      } else {
        _this.chartData[i].loading = false
      }
    } else {
      initMarkV1.searchRequest(_this, i)
    }
  },
  gettimeRange(_this, em, slot, iscontrast, scheduler) {
    let emit = JSON.parse(JSON.stringify(em))
    if (emit) {
      let req = emit.item.reqObj
      const findbookmark = _this.bookmarks.find(x => x.id === emit.item.id)
      let definition = JSON.parse(findbookmark.definition)
      let version = findbookmark.version
      em.item.timeRange = emit.v2time
      _this.$set(em.item, 'timeRange', emit.v2time)
      if (version == '2.0') {
        let isRelative = emit.v2time[0].mode
        let timeRan = _this.$commonJs.getContrastTimeV2(_this, req, emit, isRelative, iscontrast)
        req.injectedArguments.timeRange = timeRan

        let contrast = req.injectedArguments.contrastType ? req.injectedArguments.contrastType : req.contrastType
        switch (contrast) {
          case 'nocontrast':
            contrast = 'Current'
            break
          case 'contrast':
            contrast = 'Contrast'
            break
          case 'last':
            contrast = 'Last'
            break
          case 'circle':
            contrast = 'Circle'
            break
          case 'lastAndCircle':
            contrast = 'LastAndCircle'
            break
        }
        req.injectedArguments.contrastType = contrast

        _this.$set(_this.chartData[emit.index], 'reqObj', req)
        _this.$set(_this.chartData[emit.index], 'timeRange', timeRan)
        dashbdjs.searchchange(_this, emit.index, req, slot, contrast, scheduler)
      } else {
        let isRelative2 = emit.v2time[0].mode
        let temp = definition.code.slice(0, 1).toUpperCase() + definition.code.slice(1)
        let p = definition.data ? definition.data : definition
        _this.chartData[emit.index].loading = true
        _this.chartData[emit.index].option = null
        let timeRan2 = _this.$commonJs.getContrastTimeV1(_this, req, emit, isRelative2, iscontrast)
        if (emit.item.searchCode == 'contrast') {
          let times = []
          if (!iscontrast) {
            req[0].injectedArguments = {}
            req[0].injectedArguments.conditionGroup = req[0].conditionGroup
            req[0].injectedArguments.timeRange = timeRan2
            req[1].injectedArguments = {}
            req[1].injectedArguments.conditionGroup = req[0].conditionGroup
            req[1].injectedArguments.timeRange = req[1].timeRange
            times = [timeRan2[0], req[1].timeRange[0]]
          } else {
            req[0].injectedArguments = {}
            req[0].injectedArguments.conditionGroup = req[0].conditionGroup
            req[0].injectedArguments.timeRange = req[0].timeRange
            req[1].injectedArguments = {}
            req[1].injectedArguments.conditionGroup = req[0].conditionGroup
            req[1].injectedArguments.timeRange = timeRan2
            times = [req[0].timeRange[0], timeRan2[0]]
          }

          p[0].injectedArguments = req[0].injectedArguments
          p[1].injectedArguments = req[1].injectedArguments

          _this.$set(_this.chartData[emit.index], 'reqObj', req)
          _this.$set(_this.chartData[emit.index], 'timeRange', initMarkV1.initV1TimeRange(times))
        } else {
          if (req.injectedArguments) {
            req.injectedArguments.timeRange = timeRan2
            let injectedContrastType = req.injectedArguments.contrastType
            req.injectedArguments.contrastType = injectedContrastType ? injectedContrastType : req.contrastType
            if (req.injectedArguments.contrastType == 'lastandcircle') {
              req.injectedArguments.contrastType = 'lastAndCircle'
            }
          }
          p.injectedArguments = req.injectedArguments

          _this.$set(_this.chartData[emit.index], 'reqObj', req)
          _this.$set(_this.chartData[emit.index], 'timeRange', initMarkV1.initV1TimeRange(timeRan2))
        }
        if (temp == 'Template') {
          p.cdtControlValueDic.time = p.injectedArguments.timeRange
          p.templateParameters.time = JSON.stringify(p.injectedArguments.timeRange[0])
        }
        initMarkV1.getSearch(_this, emit.index, temp, p, slot)
      }
    }
  },
  searchchange: (_this, idx, req, contrastitem, type, scheduler, realtime) => {
    let bookmark = _this.bookmarks[idx]
    let version = bookmark.version
    if (version == '2.0') {
      if (bookmark.category === 'combination') {
        cardinitMark.searchchange(_this, idx, contrastitem, type, scheduler)
      } else {
        initMarkV2.searchchangeV2(_this, idx, req, contrastitem, type, scheduler, realtime)
      }
    } else {
      initMarkV1.searchchangeV1(_this, idx, req, contrastitem, type)
    }
  },
}
export default dashbdjs
