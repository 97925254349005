function settingFn(condition) {
  let setting = condition.displaySettings
  let type = condition.showType
  return {
    type: type,
    nameCN: setting.title,
    nameEN: setting.entitle,
    styleSet: setting,
    filterSet: condition.resultFilter,
  }
}
// 分组
function groupsFn(condition) {
  return [
    { code: 'itemset_a', text: condition.fieldTitle, dim_none: true },
    { code: 'itemset_b', text: '关联' + condition.fieldTitle, dim_none: true },
    { code: 'supportcount', text: '关联人数', dim_none: true },
    { code: 'confidence', text: '关联度', dim_none: true },
    { code: 'non_supportcount', text: '非关联人数', dim_none: true },
    { code: 'reverseconfidence', text: '反向关联度', dim_none: true },
    { code: 'lift', text: '提升度', dim_none: true },
  ]
}

const Format = {
  // 属性
  attr(params) {
    this.prop = params.condition.resultFilter.columns ? params.condition.resultFilter.columns : params.condition.resultFilter
    this.groups = groupsFn(params.condition)
    this.result = params.result.data
    this.setting = settingFn(params.condition)
  },
  // 指标
  propfun(item, temp, arr) {
    arr.push(temp)
  },
}

export default Format
