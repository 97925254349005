import drows1 from './drowChart'

export function Association(_this,index,query,data){
  let option = {}
  switch(query.showType){
    case 'bar':
      getLineBar()
      break
    case 'relation':
      getRelation()
      break
    case 'table':
      getTable()
      break
  }
  function getLineBar(){
    let config = {
      legend:[],
      xAxisdata:[],
      seriesdata:[],
      setting:query.displaySettings,
    }
    
    let isHorizontal = config.setting.direction == 'horizontal' ? true : false//是否横向

    let filtercolumns =  [{
      code: "supportcount",
      text: "关联人数",
    }, {
      code: "confidence",
      text: "关联度",
    }, {
      code: "non_supportcount",
      text: "非关联人数",
    }, {
      code: "reverseconfidence",
      text: "反向关联度",
    }, {
      code: "lift",
      text: "提升度",
    }]
    config.xAxisdata = data.data.map((x) => {
      return x.sys_gen_key
    })
    config.xAxisdata = isHorizontal?config.xAxisdata.reverse():config.xAxisdata
    config.legend = filtercolumns.map(x=>{
      let ser = {
        name:x.text,
        type:'bar',
        barMaxWidth: 30,
        label:{
          show:config.setting.enableLable,
          position:isHorizontal?'right':'top',
          color:_this.$basicInfo.project.theme==0?'#444':'#ddd',
          formatter: function(v){
            return  _this.$commonJs.formatnum(v.data)
          }
        },
        data:[],
        code:x.code,
        displayName:x.text,
        measure:x.text,
      }
      data.data.forEach((d) => {
        ser.data.push(d[x.code])
      })
      config.seriesdata.push(ser)

      return x.text
    })

    if(isHorizontal){
      config.xAxisdata = config.xAxisdata.map(el => {
        return el.substring(0,10)
      })
    }

    option = _this.drowLine(_this,config)
  }
  function getRelation(){
    let config = {
      nodesA:[],
      edges:[],
      relacolors: [],
    }

    let dA = [...new Set(data.data.map(x=>{return x.itemset_a}))]
    let keyDA =[]
    dA.map((tag,i) => {
      let list = data.data.filter(x=>{
        return x['itemset_a'] == tag
      })
      keyDA[i]=list
      keyDA[i]['key']=tag
    })
    
    let dB = [...new Set(data.data.map(x=>{return x.itemset_a}))]
    let keyDB =[]
    dB.map((tag,i) => {
      let list = data.data.filter(x=>{
        return x['itemset_b'] == tag
      })
      keyDB[i]=list
      keyDB[i]['key']=tag
    })

    //根据数据条数得到一个颜色数组
    let relacolors = []
    let chartColorV2 = JSON.parse(JSON.stringify(_this.chartData[index].chartColorV2))
    let colorLen = Math.ceil(keyDA.length/(chartColorV2.length))
    for(let i=0;i<=colorLen;i++){
      let newcolor = chartColorV2.map(x=>{
        let c = {}
        c.color = x
        c.opacity = (10-i)/10
        return c
      })
      relacolors = relacolors.concat(newcolor)
    }
    config.relacolors = relacolors

    //最大值
    let maxValue = 0
    data.data.forEach(x=>{
      maxValue = x['supportcount'] + x['reverseconfidence'] > maxValue ? x['supportcount'] + x['reverseconfidence'] : maxValue
    })

    config.nodesA = keyDA.map((s,idx) => {
      let size = ((s['supportcount'] + s['reverseconfidence']) / maxValue) * 80
      size = size > 10 ? size : 10
      return {
        id:s.key,
        name:s.key,
        source:s,
        itemStyle: {
          color: relacolors[idx].color,
          opacity:relacolors[idx].opacity
        },
        label:{
          color:relacolors[idx].color,
        },
        symbolSize: size,
      }
    })

    let nodesB = keyDB.map((s) => {
      return {
        id:s.key,
        name:s.key,
        source:s,
      }
    })
    let nodesAobj = keyDA.map((s) => {
      return s.key
    })

    nodesB.forEach((item) => {
      if(nodesAobj.indexOf(item.id)==-1){
        config.nodesA.push(item)
      }
    })

    let st = '', ts = '', distinctDic = {}
    let maxCount = 0
    config.edges = data.data.filter((r) => {
      let counta = r.supportcount + r.non_supportcount
      let countb = r.supportcount / r.reverseconfidence
      let count = counta > countb ? counta : countb
      maxCount = count > maxCount ? count : maxCount
      st = r.itemset_a + '||' + r.itemset_b
      ts = r.itemset_b + '||' + r.itemset_a
      if (!distinctDic[st] && !distinctDic[ts]) {
          distinctDic[st] = true
          return true
      } else {
          return false
      }
    }).map((s) => {
      return {
        source: s.itemset_a,
        target: s.itemset_b,
        value: s.supportcount
      }
    })

    //配置属性
    option = drows1.drowRelation(_this,config)
  }

  function getTable(){
    let tableHead = []
    let tableData = []

    let filtercolumns =  [{
      code: "supportcount",
      text: "关联人数",
    }, {
      code: "confidence",
      text: "关联度",
    }, {
      code: "non_supportcount",
      text: "非关联人数",
    }, {
      code: "reverseconfidence",
      text: "反向关联度",
    }, {
      code: "lift",
      text: "提升度",
    }]

    let filter = {
      key: 'itemset_a',
      label: query.fieldTitle,
    }
    tableHead.push(filter)
    filter = {
      key: 'itemset_b',
      label: '关联'+query.fieldTitle,
    }
    tableHead.push(filter)

    filtercolumns.forEach((el)=>{
      filter = {
        key: el.code,
        label: el.text,
      }
      tableHead.push(filter)
    })
    tableData = data.data

    option.data = {
      tableHead:tableHead,
      opt:tableData,
    }
  }
  
      
  return option
}
  
  