function settingFn(condition) {
  let setting = condition.setting
  let type = setting.chartType

  return {
    type: type,
    nameCN: setting.title,
    nameEN: setting.entitle,
    styleSet: setting,
    filterSet: condition.resultFilter || {},
  }
}

// 指标初始值
function propsFn(prop, measureGroup) {
  // 第一列总人数
  let totalCol = { code: 'person_number_all', text: '总人数', type: 'number' }
  let arr = [totalCol]
  prop.forEach(item => {
    let temp = {
      code: 'person_number_' + item,
      text: item.includes(measureGroup.unit) ? item : item + measureGroup.unit,
      dataType: 'number',
    }
    arr.push(temp)
  })
  return arr
}

// 分组
function groupsFn(g) {
  return [{ code: 'dimension', text: g.text, type: g.type }]
}

// 结果
function resultFn(selectedGroup, list, prop) {
  let arr = list.map(item => {
    let temp = {
      dimension_dim: selectedGroup.text == '总体' ? '总体' : item.dimension.text,
    }
    prop.forEach(p => {
      let alias = p.code.replace('person_number_', '')
      if (alias === 'all') {
        temp.person_number_all = item.total.text
      } else {
        temp[`person_number_${alias}`] = item[alias].text
        temp[`rate_${alias}`] = item[alias].rate
      }
    })
    return temp
  })
  return arr
}

const Format = {
  // 属性
  attr(params) {
    this.prop = propsFn(params.result.result.columns.slice(2), params.condition.measureGroup)
    this.groups = groupsFn(params.condition.selectedGroup)
    this.result = resultFn(params.condition.selectedGroup, params.result.result.rows, this.prop)
    this.setting = settingFn(params.condition)
  },
  propfun(item, temp, arr) {
    arr.push(temp)
  },
  // 分组
  result(r, item, temp) {
    // 补充有用的数据 rate
    let k = item.alias
    let duration = k.replace('person_number_', '')
    if (duration !== 'all' && r[`rate_${duration}`]) {
      temp[`rate_${duration}`] = (r[`rate_${duration}`] * 100).toFixed(2) + '%'
    }
  },
}

export default Format
