import drowsv1 from './drowChart'
import store from '@/store'
export function Funnel(_this, query, data) {
  // let _this = _this
  let option = {}
  let envData = {}
  envData.eventDic = {}
  envData.funnelDic = {}
  store.state.envdata.targets[0].events.forEach((item) => {
    envData.eventDic[item.code] = item
  })
  store.state.envdata.funnels.forEach((item) => {
    envData.funnelDic[item.id] = item
  })

  let config = {
    legend: [],
    seriesdata: [],
    setting: query.displaySettings,
  }

  let funnel = envData.funnelDic[query.ID]
  config.legend = funnel.steps.map(function (x) {
    return envData.eventDic[x.code].text
  })
  switch (query.showType.code) {
    case 'funnel':
      getFunnelData()
      break
    case 'table':
      getTableData()
      break
  }

  //漏斗分析-漏斗图
  function getFunnelData() {
    let ser = {
      type: 'funnel',
      data: [],
      top: 26,
      bottom: 20,
    }
    ser.data = funnel.steps.map((x, index) => {
      let idx = index + 1
      return {
        name: envData.eventDic[x.code].text,
        value: data.total[0]['conversion_count_' + idx],
      }
    })
    config.seriesdata.push(ser)

    //配置属性
    option = drowsv1.drowFunnel(_this, config)
    option.funnelTips = {
      name: query.selectedGroups[0].text,
    }
    option.total = data.total.map(x=>{
      let rate = {
        ...x,
        title:x.dimension,
        value:(x.overall_conversion_rate_last * 100).toFixed(2) + '%',
      }
      return rate
    })
    option.envData = envData
  }

  //漏斗图导出数据
  getExcelsObj()
  //漏斗分析-表格
  function getTableData() {
    option.activeTabName = 'tab0'
    option.data = gettabledata()
  }

  function getExcelsObj() {
    let excelsFunnelObj = {}
    let tabledata = gettabledata()
    tabledata.forEach((x, idx) => {
      let funnelobj = funnelfun(x)
      excelsFunnelObj['tab' + idx] = funnelobj
    })

    option.excelsObj = excelsFunnelObj['tab0']
    option.excelsFunnelObj = excelsFunnelObj

    option.tabledata = tabledata
  }

  //获取表格数据
  function gettabledata() {
    let tabledata = []
    config.legend.forEach((l, idx) => {
      let list = {
        tabname: idx == 0 ? '总览' : config.legend[idx - 1] + '转化' + config.legend[idx],
        tableHead: [],
        opt: [],
      }
      if (idx == 0) {
        query.selectedGroups.forEach((el) => {
          let group = {
            alias: 'dimension',
            title: el.text,
          }
          list.tableHead.push(group)
          config.legend.forEach((x, i) => {
            group = {
              alias: 'conversion_count_'+(i+1),
              title: x,
              usage:'measure',
            }
            list.tableHead.push(group)
          })
          group = {
            alias: 'overall_conversion_rate_last',
            title: '整体转化率',
            usage:'rate',
          }
          list.tableHead.push(group)

          list.opt = {}
          // data.list.forEach((x,i)=>{
          //   list.opt['tab'+i] = [x]
          // })
          list.opt = data.total
        })
      } else {
        query.selectedGroups.forEach((el) => {
          let group = {
            alias: 'dimension',
            title: el.text,
          }
          list.tableHead.push(group)
          group = {
            alias: 'conversion_count',
            title: '转化人数',
            usage:'measure',
          }
          list.tableHead.push(group)
          group = {
            alias: 'overall_conversion_rate',
            title: '转化率',
            usage:'rate',
          }
          list.tableHead.push(group)
          group = {
            alias: 'churn_count',
            title: '流失人数',
            usage:'measure',
          }
          list.tableHead.push(group)
          group = {
            alias: 'churn_rate',
            title: '流失率',
            usage:'rate',
          }
          list.tableHead.push(group)
          list.opt = {}
          let steplist = data.list.filter(x=>{
            return x.step == idx+1
          })
          steplist.map(x=>{
            if(x['overall_conversion_rate'] !== 0 && !x['overall_conversion_rate']){
              x['overall_conversion_rate'] = 1-x['churn_rate']
            }
            return x
          })
          list.opt = steplist
        })
      }

      tabledata.push(list)
    })
    return tabledata
  }

  function funnelfun(excels){
    let tableObj = []
    let thead = []
    let tbody = []
    tableObj.allDimValues = []
    tableObj.otherDimValues = ['']
    tableObj.groups = []
    tableObj.prop = []
    
    for(let item in excels.fields){
      let list = {
        prop:excels.fields[item],
        title:item,
        label:item
      }
      tableObj.prop.push(list)
    }

    thead = excels.tableHead.map(x=>{
      return {
        label:x.title,
        prop:x.alias
      }
    })
    tbody = excels.opt
    
    return {tableObj, thead, tbody}
  }

  return option
}
