import BI_config from '@/utils/config'
import axios from 'axios'
import store from '@/store'
import Vue from 'vue'
import { hashCode } from '@/utils/auth'
let myv = new Vue()

var service = axios.create({
  baseURL: BI_config.baseApi,
  timeout: 160000,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
})
service.interceptors.request.use(
  function(config) {
    config.cancelToken = new axios.CancelToken(cancel => {
      store.commit('pushToken', {
        cancelToken: cancel,
      })
    })
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    if (response.data.code >= 20000 && response.data.data) {
      //销控
      MpGoHandle.init(response.data.data)
    }
    return response
  },
  error => {
    error.response && postTrace(error.response.headers)
    if (error.response) {
      let data = error.response.data
      if (data.code === 401) {
        location.href = `${BI_config.baseLogin}?callbackUrl=${encodeURIComponent(location.href)}`
      }
      let code = hashCode(data.message)
      if (!store.state.errorCounter.has(code)) {
        store.state.errorCounter.add(code)
        myv.$alert(data.message, '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: action => {
            setTimeout(() => {
              store.state.errorCounter.delete(code)
            }, 500)
          },
        })
      }
    }
    // 接口异常
    return Promise.reject(error) // 返回接口返回的错误信息
  }
)

let postTrace = headers => {
  axios
    .post(
      `${BI_config.baseApi}/api/trace`,
      { referer: window.location.href },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'R-TraceId': headers['r-traceid'],
        },
      }
    )
    .then(res => {})
}

export default function request(opts, type) {
  let opts1 = { method: type, ...opts }
  return new Promise((resolve, reject) => {
    return service(opts1)
      .then(res => {
        if (!(res && res.data)) return
        resolve(res.data)
        if (res.data.error) {
          let code = hashCode(res.data.message)
          if (!store.state.errorCounter.has(code)) {
            store.state.errorCounter.add(code)
            myv.$alert(res.data.message, '提示', {
              confirmButtonText: '确定',
              callback: action => {
                setTimeout(() => {
                  store.state.errorCounter.delete(code)
                }, 500)
              },
            })
          }
        }
      })
      .catch(e => {
        console.error(e)
        reject(e)
      })
  })
}
