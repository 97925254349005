export function Behavior1(_this, query, data, tbdata) {
  // let _this = this
  let option = {}
  //判断是否无对比，对比，同比，环比，同环比 来创建图例和数据
  //query.contrastType
  //query.injectedArguments.contrastType

  let contrastType = query.injectedArguments && query.injectedArguments.contrastType ? query.injectedArguments.contrastType : query.contrastType
  contrastType = contrastType == 'lastcircle' ? 'circle' : contrastType

  let isTotal =
    query.selectedGroups.length == 0 || (query.selectedGroups.length == 1 && ['allField', '_tag'].indexOf(query.selectedGroups[0].code) > -1) ? true : false

  //根据分组和指标
  let querycolumns = query.resultFilter.columns.filter(x => {
    return x.selected
  })
  let categories = []
  categories =
    !isTotal && querycolumns.length > 0
      ? _this.$commonJs.formatDimValue(_this.$targets, query, data.current.firstDimValues, {
          onlyFirst: true,
        })
      : ['总体']

  let allResult = data.current.result
  allResult = contrastType != 'nocontrast' ? allResult.concat(data.contrast.result) : allResult
  allResult = contrastType == 'lastAndCircle' ? allResult.concat(data.circle.result) : allResult

  switch (query.displaySettings.mode) {
    case 'table':
      getTable()
      break
  }
  function getTable() {
    tbdata.setting = query.displaySettings
    option = tbdata
  }
  return option
}
