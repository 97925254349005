import { getMallMap } from '@/api/apiV2_dashboard'
import { Search } from '@/api/apiV1'

import Table from '@/components/tables/tableHead'
import { FD } from '@/components/tables/formatV1'
import { allColor } from '@/datajs/static.js'
import { Behavior } from './Behavior'
import { Behavior1 } from './Behavior1'
import { Retention } from './Retention'
import { Funnel } from './Funnel'
import { Association } from './Association'
import store from '@/store'

let initMarkV1 = {
  allColor: allColor,
  //初始化 卡片数据
  init(_this, BI_config, v, el, i) {
    let definition = JSON.parse(el.definition)
    let p = definition.data ? definition.data : definition
    let defcode = definition.code

    let reqObj = JSON.parse(JSON.stringify(p))
    reqObj.content = el.content
    if (defcode == 'retention' || defcode == 'scatter') {
      reqObj.title = reqObj.setting.title
      reqObj.mode = reqObj.setting.chartType
    } else if (defcode == 'association' || defcode == 'attribute') {
      reqObj.title = reqObj.displayTitle
      reqObj.mode = reqObj.showType
    } else if (defcode == 'segment') {
      reqObj.title = reqObj.text
      reqObj.mode = 'card'
    } else {
      reqObj.title =
        reqObj.displaySettings && reqObj.displaySettings.title
          ? reqObj.displaySettings.title
          : reqObj.displayTitle
          ? reqObj.displayTitle
          : reqObj[0].displayTitle
      reqObj.mode =
        reqObj.displaySettings && reqObj.displaySettings.mode ? reqObj.displaySettings.mode : reqObj.showType ? reqObj.showType.code : reqObj[0].showType
    }
    // 初始v1时间格式改为V2格式，请求的时候 V1的时间再改为V1的格式
    let timeRange = null
    if (Array.isArray(reqObj.timeRange)) {
      timeRange = reqObj.timeRange
    } else {
      if (reqObj.timeRange) {
        let arr = []
        arr.push(reqObj.timeRange)
        timeRange = arr
      } else if (defcode == 'contrast') {
        timeRange = reqObj.map(x => {
          return x.timeRange[0]
        })
      } else if (defcode == 'attribute') {
        timeRange = null
      } else if (defcode == 'segment') {
        timeRange = null
      } else {
        let time = reqObj.templateParameters.time
        let arr = []
        arr.push(JSON.parse(time))
        timeRange = arr
      }
    }

    if (reqObj.contrastType == 'lastcircle') {
      reqObj.contrastType = 'circle'
    }
    reqObj.contrastType = reqObj.contrastType ? reqObj.contrastType.toLowerCase() : ''

    reqObj.injectedArguments = {
      conditionGroup: {
        conditions: [],
        operator: 'and',
      },
    }

    let posv2 = el.positionV2
    if (!posv2) {
      posv2 = el.position
    }
    let pos = {
      id: el.id,
      x: posv2.col || posv2.col == 0 ? posv2.col : 4,
      y: posv2.row || posv2.row == 0 ? posv2.row : 4,
      w: posv2.x || posv2.x == 0 ? posv2.x : 4,
      h: posv2.y || posv2.y == 0 ? posv2.y : 4,
      i: i,
    }
    _this.position[i] = pos
    let mainColoridx = _this.basicInfo.project.mainColor
    //1.0卡片 的跟随主题色
    let mainColor = allColor['color' + mainColoridx]
    let url = BI_config.SiteHost + _this.$commonJs.getxBase64Url(el, v.dashboard, store.state.projectID)
    let chart = {
      index: i,
      id: el.id,
      lock: el.lock,
      mapkey: i + '-',
      code: el.code,
      searchCode: defcode,
      createTime: el.createTime,
      createUserName: el.createUserName,
      updateTime: el.updateTime,
      updateUserName: el.updateUserName,
      definition: definition,
      reqObj: reqObj,
      timeRange: initMarkV1.initV1TimeRange(timeRange),
      loading: false,
      url: url,
      option: '',
      otherset: {},
      version: el.version,
      rdMainColor: _this.basicInfo.project.mainColor,
      chartColorV2: mainColor[0],
      rdChartColor: el.chartColorV2 ? el.chartColorV2 : 0,
    }

    let getslot = []
    let slots = []
    if (p.selectedGroups) {
      getslot = p.selectedGroups.filter((g, slotidx) => {
        return g.code == 'eventTime' && g.mode == 'normal' && slotidx == 0
      })
      slots = getslot.length > 0 ? getslot[0].value.split('_') : ''
    }

    chart.otherset.slot = getslot.length > 0 ? (slots.length > 1 ? slots[1] : slots[0]) : null

    return chart
  },
  searchRequest: (_this, index) => {
    let definition = JSON.parse(_this.bookmarks[index].definition)
    let p = definition.data ? definition.data : definition
    _this.copyChartData[index]['query'] = p
    _this.copyChartData[index]['bookmarkid'] = _this.bookmarks[index].id
    // 根据获取到的数据组装成 echarts 需要的数据
    switch (definition.code) {
      case 'behavior': //事件分析
        initMarkV1.getSearch(_this, index, 'Behavior', p)
        break
      case 'contrast': //对比分析
        initMarkV1.getSearch(_this, index, 'Contrast', p)
        break
      case 'funnel': //漏斗分析
        initMarkV1.getSearch(_this, index, 'Funnel', p)
        break
      case 'retention': //留存分析
        initMarkV1.getSearch(_this, index, 'Retention', p)
        break
      case 'scatter': //分布分析
        initMarkV1.getSearch(_this, index, 'Scatter', p)
        break
      case 'attribute': //属性分析
        initMarkV1.getSearch(_this, index, 'Attribute', p)
        break
      case 'segment': //分群分析
        initMarkV1.getSearch(_this, index, 'Segment', p)
        break
      case 'template': //模板分析
        initMarkV1.getSearch(_this, index, 'Template', p)
        break
      case 'association': //关联分析
        initMarkV1.getSearch(_this, index, 'Association', p)
        break
      case 'path': //路径分析
        initMarkV1.getSearch(_this, index, 'Path', p)
        break
    }
  },
  async getSearch(_this, idx, temp, p, slottype) {
    _this.chartData[idx].loading = true
    let param = {
      dashboardID: _this.$route.hash.replace('#/', ''),
      disableLoading: false,
      initialized: false,
      projectID: store.state.projectID,
    }
    let hotmapdata = temp == 'Behavior' ? await initMarkV1.hotMap(_this, idx, p) : '1'
    Search(temp, param, p)
      .then(d => {
        _this.copyChartData[idx]['temp'] = temp
        let copd = ''
        if (_this.chartData[idx]) {
          let option = null
          let FD_obj = null
          let v2d = {}
          let v2_cache = {}
          if (p.displaySettings && ['funnel'].includes(p.displaySettings.mode)) {
            v2d.rows = d.list || d.data || d.current.result || d.current || d.result.rows
            v2d.setting = {
              styleSet: p.displaySettings,
              type: p.displaySettings.mode,
            }
          } else if (p.displaySettings && ['relation'].includes(p.displaySettings.mode)) {
            v2d.rows = d.list || d.data || d.current.result || d.current || d.result.rows
            v2d.setting = {
              styleSet: p.displaySettings,
              type: p.displaySettings.mode,
            }
            let FD_cache = new FD({ condition: p, result: d, analysisType: temp.toLowerCase() })
            v2_cache = FD_cache.fortmat()
          } else if (d.error) {
            v2d.setting = {
              styleSet: p.displaySettings,
              type: p.displaySettings.mode,
            }
            v2d = null
          } else {
            FD_obj = new FD({ condition: p, result: d, analysisType: temp.toLowerCase() })
            v2d = FD_obj.fortmat()
          }
          copd = JSON.parse(JSON.stringify(d))
          _this.copyChartData[idx]['mode'] = v2d.setting.type
          v2d = v2d ? initMarkV1.getFilterData1(v2d, slottype) : v2d
          if (!v2d.rows || (v2d.rows && v2d.rows.length == 0)) {
            option = { noData: true }
            _this.copyChartData[idx]['data'] = null
          } else {
            // *** 暂时先把 漏斗分析funnel 区分开 有时间再整合 ***
            if (['funnel'].includes(v2d.setting.type)) {
              option = v2d.setting.type == 'bubble' ? Behavior1(_this, p, d) : Funnel(_this, p, d)
              option.noData = false
              _this.chartData[idx]['excelsObj'] = option.excelsObj

              _this.copyChartData[idx]['data'] = copd
              _this.chartData[idx]['data'] = v2d
              _this.chartData[idx]['orginResult'] = v2d
              _this.chartData[idx]['settings'] = v2d.setting
              _this.copyChartData[idx]['tableObj'] = option.excelsObj.tableObj
              _this.copyChartData[idx]['inittableObj'] = option.excelsObj.initdata
              _this.chartData[idx]['tableObj'] = option.excelsObj.tableObj
              _this.chartData[idx]['inittableObj'] = option.excelsObj.initdata
            } else if (['relation'].includes(v2d.setting.type)) {
              if (temp == 'Association') {
                option = Association(_this, idx, p, d)
              } else {
                option = v2d.setting.type == 'bubble' ? Behavior1(_this, p, d) : Retention(_this, p, d)
              }
              // temp = v2d.setting.type == 'bubble' ? 'Behavior1' : temp
              option.noData = false

              let opts = v2d.setting
              let tableObj = new Table({
                opts,
                columns: v2_cache.columns,
                result: v2d.rows,
              })
              let excelsObj = initMarkV1.getExcelData(tableObj.init())

              let initdata = tableObj.init()
              _this.copyChartData[idx]['data'] = copd
              _this.chartData[idx]['data'] = v2d
              _this.chartData[idx]['orginResult'] = v2d
              _this.copyChartData[idx]['excelsObj'] = excelsObj
              _this.chartData[idx]['excelsObj'] = excelsObj
              _this.chartData[idx]['settings'] = v2d.setting
              _this.copyChartData[idx]['tableObj'] = tableObj
              _this.copyChartData[idx]['inittableObj'] = initdata
              _this.chartData[idx]['tableObj'] = tableObj
              _this.chartData[idx]['inittableObj'] = initdata
            } else {
              let opts = v2d.setting
              let tableObj = new Table({
                opts,
                columns: v2d.columns,
                result: v2d.rows,
              })
              d = v2d
              p = {
                ...p,
                settings: v2d.setting,
              }
              let initdata = tableObj.init()
              option = Behavior(_this, p, d, opts, initdata, hotmapdata, _this.chartData[idx])
              option.noData = false
              _this.copyChartData[idx]['data'] = d
              _this.copyChartData[idx]['styleset'] = p
              _this.copyChartData[idx]['tableObj'] = tableObj
              _this.copyChartData[idx]['inittableObj'] = initdata
              _this.chartData[idx]['data'] = d
              _this.chartData[idx]['tableObj'] = tableObj
              _this.chartData[idx]['inittableObj'] = initdata
              _this.chartData[idx]['orginResult'] = d
              let excelsObj = initMarkV1.getExcelData(initdata)
              _this.copyChartData[idx]['excelsObj'] = excelsObj
              _this.chartData[idx]['excelsObj'] = excelsObj
              _this.chartData[idx]['resizedidx'] = 0
              _this.chartData[idx]['settings'] = v2d.setting
              if (opts.type === 'card' && option && option.arr.length > 0) {
                _this.$set(_this.chartData[idx], 'curcard', option.arr[0])
                _this.$set(_this.chartData[idx], 'curcardlabel', option.arr[0].label)
              }
            }
          }
          _this.resetChartOption(idx, option)
          _this.chartData[idx].loading = false
        }
      })
      .catch(err => {
        console.error(err)
        _this.copyChartData[idx]['data'] = null
        if (_this.chartData[idx]) _this.chartData[idx].loading = false
      })
  },

  getExcelData: table => {
    let excels = {
      tbdata: [],
      fields: {},
    }
    excels.tbdata = table.tbody
    table.thead.forEach(x => {
      // excels.fields[x.label] = x.prop || x.children[0].prop
      if (['对比值', '对比率', '同比值', '同比率', '环比值', '环比率'].includes(x.label)) {
        let curevent = table.thead.find(k => {
          return x.prop.includes(k.prop)
        })
        excels.fields[curevent.label + '-' + x.label] = x.prop || x.children[0].prop
      } else {
        excels.fields[x.label] = x.prop || x.children[0].prop
      }
      if (x.prop == 'event_time_dim' || x.prop == 'day_dim') {
        excels.fields[x.label] = {
          //判断是否为日期年月的情况 年月格式excel没有 2020-01 的格式会自动转为英文格式 改为 2020年01月
          callback: value => {
            let times = value[x.prop].split('-')
            let time = ''
            time = times.length == 2 ? times[0] + '年' + times[1] + '月' : value[x.prop]
            return time
          },
        }
      }
    })
    return excels
  },
  getFilterData1: (v2d, slottype) => {
    let filterdata = {}
    let filterSet = v2d.setting.filterSet
    //过滤掉指标和指标相关
    filterdata.columns =
      filterSet && filterSet.columns
        ? v2d.columns.filter(x => {
            let curMeasure = filterSet.columns.filter(k => {
              let kcode = k.code.replace('_right', '_contrast')
              let xalias = x.alias
              let xaliasArr = xalias.split('_')
              let lastname = xaliasArr.pop()
              if (['contrast', 'contrastRate', 'last', 'lastRate', 'circle', 'circleRate'].includes(lastname)) {
                xalias = xalias.replace('_' + lastname, '')
              }

              if (kcode === xalias) {
                x.title = k.text
              }
              return kcode === xalias
            })
            return curMeasure.length == 0 || curMeasure[0].selected
          })
        : v2d.columns
    //过滤掉分组，1.0分组里的 0和1 转化成 是和否
    let codes =
      filterSet &&
      filterSet.codes &&
      filterSet.codes != 'All' &&
      filterSet.codes.map(x => {
        x = x.replace(',1,', ',是,')
        x = x.replace(',0,', ',否,')
        return x
      })
    filterdata.rows =
      filterSet && filterSet.codes && filterSet.codes != 'All'
        ? v2d.rows.filter(x => {
            return codes.includes(x.sys_gen_key)
          })
        : v2d.rows

    //最后根据columns的start和end 筛选结果 同环比时任意包含一个都显示
    //如果是修改 slot 则不用筛选
    filterdata.rows =
      filterSet && filterSet.columns && filterSet.columns.length > 0 && slottype != 'slot'
        ? filterdata.rows.filter(x => {
            let c = filterSet.columns.find(k => {
              return x[k.code] || x[k.code] === 0
            })
            let isShow = false
            let elseContrast = ['_contrast', '_last', '_circle']
            if (c) {
              isShow = x[c.code] >= c.start && x[c.code] <= c.end
              elseContrast.forEach(t => {
                let isdisplay = x[c.code + t] ? x[c.code + t] >= c.start && x[c.code + t] <= c.end : false
                isShow = isShow || isdisplay
              })
            }

            return isShow
          })
        : filterdata.rows
    filterdata.setting = v2d.setting
    return filterdata
  },

  searchchangeV1: (_this, idx, req, contrastitem, type) => {
    let bookmark = _this.bookmarks[idx]
    let definition = JSON.parse(bookmark.definition)
    let temp = ''
    let p = ''
    let p2 = {}

    temp = definition.code.slice(0, 1).toUpperCase() + definition.code.slice(1)
    p = definition.data ? definition.data : definition
    _this.chartData[idx].loading = true
    _this.chartData[idx].option = null

    let inject = req.injectedArguments

    if (type == 'contrast' || type == 'Contrast') {
      let contrast = req.contrastType
      let v2item = {}
      v2item.v2time = inject && inject.timeRange ? inject.timeRange : req.timeRange
      let timeRan = _this.$commonJs.getContrastTimeV1(_this, req, v2item, contrastitem)
      inject.timeRange = timeRan
      inject.contrastType = contrast

      p2.ContrastType = contrast
    } else if (type == 'slot') {
      inject.timeRange = inject.timeRange ? inject.timeRange : req.timeRange
      let contrast = inject.contrastType ? inject.contrastType : req.contrastType

      inject.contrastType = contrast
      let selectedGroups = req.selectedGroups.map(x => {
        if (x.code == 'eventTime') {
          x = { code: 'eventTime', type: 'date', category: 3, value: contrastitem, text: '事件发生时间' }
        }
        return x
      })
      inject.selectedGroups = selectedGroups
      p2.ContrastType = req.contrastType
    } else if (type == 'mallid') {
      p.conditionGroup = JSON.parse(JSON.stringify(req.conditionGroup))
      inject.conditionGroup = JSON.parse(JSON.stringify(req.conditionGroup))
      inject.timeRange = inject.timeRange || req.timeRange
    } else if (type == 'refresh') {
    }
    if (inject && inject.timeRange) {
      req.timeRange = inject.timeRange
    }

    _this.$set(_this.chartData[idx], 'reqObj', req)
    _this.$set(_this.chartData[idx], 'timeRange', initMarkV1.initV1TimeRange(inject.timeRange))
    p.injectedArguments = inject
    if (temp == 'Template') {
      p.cdtControlValueDic.time = p.injectedArguments.timeRange
    }

    initMarkV1.getSearch(_this, idx, temp, p, type)
  },
  getV2timeRan(_this, req, timeRange) {
    let time = timeRange
    let injectedArguments = req.injectedArguments
    if (injectedArguments.timeRange) {
      time = injectedArguments.timeRange
    }
    let t1 = JSON.parse(JSON.stringify(time[0]))
    if (t1.mode == 'Relative') {
      let showtime = initMarkV1.getshowdata(_this, t1)
      t1.start = showtime[0]
      t1.end = showtime[1]
      t1.mode = 'Absolute'
    }
    const diff_days = _this.moment(t1.end).diff(_this.moment(t1.start), 'days')
    t1.end = _this
      .moment(t1.start)
      .subtract(1, 'days')
      .format('YYYY-MM-DD')
    const date2 = _this
      .moment(t1.start)
      .subtract(diff_days + 1, 'days')
      .format('YYYY-MM-DD')
    t1.start = date2
    time[1] = t1
    return time
  },
  async hotMap(_this, idx, searchParam) {
    let mapData = ''
    let event = searchParam.events
    let group = searchParam.groups
    let filter = searchParam.filter
    if (group && group.length == 2 && filter && filter.conditions.length == 2) {
      let conditions = filter.conditions
      let mall = filter.conditions.filter(x => {
        return x.code == '_mall_id' && x.operator == '=' && x.values.length == 1
      })[0]
      let floor = filter.conditions.filter(x => {
        return x.code == '_floor_id' && x.operator == '=' && x.values.length == 1
      })[0]
      if (
        event.length == 1 &&
        group.length == 2 &&
        ['_shop_name', '_shop_id'].includes(group[0].code) &&
        ['_shop_name', '_shop_id'].includes(group[1].code) &&
        conditions.length == 2 &&
        filter.operator == 'and' &&
        mall &&
        floor
      ) {
        let p = {
          mallID: mall.values[0],
          floorID: floor.values[0],
        }
        await getMallMap(p).then(v => {
          mapData = v.data
        })
      } else {
        mapData = 1
      }
    } else {
      mapData = 1
    }
    return mapData
  },

  initV1TimeRange(times) {
    let range = null
    let timeRange = []
    times = times ? times : []
    times.forEach(time => {
      let model = time && time.model
      if (model == 'Absolute') {
        let curtime = time
        range = {
          start: curtime.dates[0],
          end: curtime.dates[1],
          mode: curtime.model,
        }
      }
      if (model == 'Relative') {
        let curtime = time
        let defines = []
        if (curtime.isCustomDatePeriod) {
          curtime.dates.forEach(x => {
            let timeArr = x.split('-')
            let list = {}
            if (curtime.unit == 'Year') {
              list.value = timeArr[1] + '-' + timeArr[2]
            } else if (['Month', 'Week'].includes(curtime.unit)) {
              list.dayVal = parseInt(timeArr[2])
            }
            defines.push(list)
          })
        } else {
          let list = {
            unitValue: curtime.value == '0' ? curtime.value : '-' + curtime.value,
          }
          defines.push(list)
        }
        range = {
          mode: curtime.model,
          relative: {
            defines: defines,
            unit: curtime.unit,
          },
        }
      }
      if (model == 'Festival') {
        let curtime = time
        range = {
          mode: 'StatutoryFestival',
          start: curtime.dates[0],
          end: curtime.dates[1],
        }
      }
      if (model == 'CustomFestival') {
        let curtime = time
        range = {
          mode: 'CustomFestival',
          start: curtime.dates[0],
          end: curtime.dates[1],
        }
      }

      timeRange.push(range)
    })
    timeRange = timeRange.length > 0 ? timeRange : null
    return timeRange
  },
}

export default initMarkV1
