import tableUtil from '../tableUtil'

const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

// 类型转化
const dataTypeEnum = {
  bool: 'Boolean',
  string: 'String',
  number: 'Int',
  date: 'Date',
}

/**
 * 格式化v1版模型数据
 * 
 * 转化后版本
 * columns: [{ alias: 'shop__mall_name_dim', usage: 'Group', title: '商场名称', dataType: 'String' }]
 * rows: [{
      shop__mall_name_dim: '-',
      user__hascar_dim: '-',
      '25001_user_fixed_user_mid_distinct': 12558,
      '25001_event_fixed_event_id_count': 20211,
      sys_gen_key: '-,-',
    }]
 */
class FormatData {
  /**
   * @param {Object} params
   */
  constructor(params) {
    this.analysisType = params.analysisType
    // 对应模型属性加工
    modules[params.analysisType].attr.call(this, params)
    this.setting = this.setting || {}
    this.setting.analysisType = params.analysisType
  }
  /**
   * 指标
   * {
        code: 'distinct_totalcount_15004',
        text: '参与活动的总人数',
        selected: true,
        start: 3630,
        end: 5945,
    }
   */
  prop_fn(prop) {
    let arr = []
    prop.forEach(item => {
      let temp = {}
      temp.alias = item.code
      temp.title = item.text
      temp.usage = 'Measure'
      temp.dataType = (item.type && dataTypeEnum[item.type]) || 'Int'
      // 加工对应模型指标
      modules[this.analysisType].propfun.call(this, item, temp, arr)
    })
    return arr
  }
  groups_fn(groups) {
    let arr = []
    groups.forEach(item => {
      let temp = {
        ...item,
      }
      // code: 'eventTime' -> day_dim: '2018-09-01 00:00:00'
      if (item.code === 'eventTime') {
        temp.alias = 'day_dim'
      } else if (!item.dim_none) {
        temp.alias = item.code + '_dim'
      } else {
        temp.alias = item.code
      }
      temp.title = item.text
      temp.usage = 'Group'
      temp.dataType = (item.type && dataTypeEnum[item.type]) || 'String'
      arr.push(temp)
    })
    return arr
  }
  /**
   *{
        distinct_totalcount_15004: 3630,
        totalcount_15004: 4558,
        mallname_dim: '天津大悦城',
        _hascar_dim: '0',
        sys_gen_key: '0,天津大悦城',
        sys_order: {
          distinct_totalcount_15004: 3630,
          totalcount_15004: 4558,
          mallname_dim: '0_天津大悦城',
          _hascar_dim: '0_0',
        },
      },
   *
   */
  result_fn(result, prop, groups) {
    let arr = []
    let contrastType = this.contrastType
    // 重新生成每条数据（根据表头key，过滤无效数据）
    result.forEach(r => {
      let temp = {}
      let sk = []
      // 分组
      groups.forEach(item => {
        let v = ''
        let k = item.alias
        if (item.dataType === 'Boolean') {
          v = r[k] === '0' ? '否' : r[k] === '1' ? '是' : '-'
        } else {
          v = r[k]
        }

        if (item.dataType === 'Date') {
          // 时间分组
          let date_type = item.value
          v = formate_date(v, date_type)
          // 有对比 (新增对比时间)
          if (contrastType !== 'nocontrast') {
            if (r.hasOwnProperty(`${k}_date`)) {
              temp[k + '_date'] = formate_date(r[k + '_date'], date_type)
            }
            // 有环比
            if (r.hasOwnProperty(`${k}_circle_date`)) {
              temp[k + '_circle_date'] = formate_date(r[k + '_circle_date'], date_type)
            }
          }
        }
        temp[k] = v
        temp[k + '_orig'] = r[k + '_orig'] ? r[k + '_orig'] : r[k]

        // sys_gen_key
        sk.push(v)
      })
      temp.sys_gen_key = sk.join('[|]')

      // 指标
      prop.forEach(item => {
        if (item.usage !== 'Measure') {
          return false
        }
        let k = item.alias
        // 区分 关联分析模型 百分比指标 和 占比
        if (k == 'confidence' || k == 'reverseconfidence') {
          temp[k] = r[k] && tableUtil.toFixed(Number(r[k] * 100)) + '%'
        } else {
          temp[k] = r[k] && tableUtil.toFixed(Number(r[k]))
        }

        // 新增对比指标数据
        if (contrastType !== 'nocontrast') {
          if (r.hasOwnProperty(`${k}_value`)) {
            contrastType = contrastType === 'lastAndCircle' ? 'last' : contrastType
            let v = r[k + '_loop']
            v = v == null ? '-' : (v * 100).toFixed(2) + '%'
            temp[k + `_${contrastType}Rate`] = v
            temp[k + `_${contrastType}`] = tableUtil.toFixed(r[k + '_value'])
          }
          // 有环比
          if (r.hasOwnProperty(`${k}_circle_value`)) {
            let v = r[k + '_circle_loop']
            v = v == null ? '-' : (v * 100).toFixed(2) + '%'
            temp[k + '_circleRate'] = v
            temp[k + '_circle'] = tableUtil.toFixed(r[k + '_circle_value'])
          }
        }

        // 加工对应模型数据
        typeof modules[this.analysisType].result === 'function' && modules[this.analysisType].result.call(this, r, item, temp)
      })
      arr.push(temp)
    })
    return arr
  }
  fortmat() {
    // prop
    let prop = this.prop_fn(this.prop)
    // groups
    let groups = this.groups_fn(this.groups)
    // 合并
    let columns = [].concat(groups).concat(prop)
    let result = this.result_fn(this.result, prop, groups)
    return {
      columns: columns,
      rows: result,
      setting: this.setting,
    }
  }
}

// 时间格式化
function formate_date(v, type) {
  let s = v
  // 小时 2018-09-01 06:00:00
  let arrTime = v.split(' ')
  let arrDate = arrTime[0].split('-')
  switch (type) {
    case 'hour':
      // 2018-09-01 06:00:00 -> 2018-09-01 06h
      s = `${arrTime[0]} ${arrTime[1].split(':')[0]}h`
      break
    case 'week':
      s = v !== '-' ? `${v}(Week ${getWeek(v)})` : v
      break
    case 'month':
      s = arrDate.slice(0, 2).join('-')
      break
    case 'year':
      s = arrDate[0]
      break
  }
  return s
}

function getWeek(thisDay) {
  let year = new Date(thisDay).getFullYear()
  let firstDay = `${year}-01-01`
  let firstDayWeek = new Date(firstDay).getDay()
  // 起始日期 毫秒数（周一开始）
  let firstTime = new Date(firstDay).getTime() - (firstDayWeek - 1) * 86400000
  let T = new Date(thisDay).getTime() - firstTime
  let weeks = Math.ceil(T / (3600 * 24 * 1000) / 7) + 1
  return weeks > 52 ? 1 : weeks
}

class FD extends FormatData {
  constructor(params) {
    super(params)
  }
}

// 转换 eventTime

export { FD }
