import store from '@/store'

class Scheduler {
  constructor(maxCount = 1, fullPath = '') {
    //线程数量
    this.maxCount = maxCount
    this.list = []
    this.index = 0
    this.fullPath = fullPath
  }

  add(fn) {
    this.list.push(fn)
  }

  start() {
    for (let i = 0; i < this.maxCount; i++) {
      this.request()
    }
  }

  isJump() {
    return store.state.fullPath !== this.fullPath
  }

  request() {
    if (!this.list || !this.list.length || this.index >= this.maxCount) return
    if (this.isJump()) return
    this.index++
    const fn = this.list.shift()
    fn().then(() => {
      this.index--
      this.request()
    })
  }
}

export default Scheduler
