/**
 * 结果
 * @param {*} list
 * '25001<->荟聚宝贝[*]<:>25001<->时尚'
 *
 */
function settingFn(condition) {
  let setting = condition.displaySettings
  let type = setting.mode
  if (setting.mode == 'doubleAxis') {
    type = 'multiaxialBar'
  } else if (setting.mode == 'multiAxisLine') {
    type = 'multiaxialLine'
  }
  //路径分析 筛选里的人数code也改为 user_fixed_user_mid_distinct
  let resultFilter = condition.resultFilter.columns ? condition.resultFilter.columns : condition.resultFilter
  const filtercolumns = resultFilter.map(x => {
    if (x.code == 'number') {
      x.code = 'user_fixed_user_mid_distinct'
    }
    return x
  })
  return {
    type: type,
    nameCN: setting.title,
    nameEN: setting.entitle,
    styleSet: setting,
    filterSet: { columns: filtercolumns },
  }
}

function resultFn(list, selectedEvents) {
  let enumEvent = {}
  // 25001: "CRM会员消费"
  selectedEvents.forEach(e => {
    enumEvent[e.code] = e.text
  })
  let reg = /(\d+)?(<->)?([^[]+)?([^\d]+)?(\d+)?(<->)?(.*)?/
  list.forEach(item => {
    const copy_item = JSON.parse(JSON.stringify(item))
    let arr = []
    let p_arr = copy_item.path.split('<:>')
    p_arr.forEach(pitem => {
      pitem = pitem
        .replace('<->', '-')
        .replace('[*]', '') //[*] 标记为路径中间的情况
        .replace(/\d+/, function(r) {
          return enumEvent[r]
        })
      arr.push(pitem)
    })
    item.path = arr.join('-->')
    item.path_orig = copy_item.path
    item.user_fixed_user_mid_distinct = copy_item.number
  })
  return list
}
const Format = {
  // 属性
  attr(params) {
    this.prop = [{ code: 'user_fixed_user_mid_distinct', text: '人数' }]
    this.groups = [{ code: 'path', text: '路径', dim_none: true }]
    this.result = resultFn(params.result.data, params.condition.selectedEvents)
    this.setting = settingFn(params.condition)
  },
  // 指标
  propfun(item, temp, arr) {
    arr.push(temp)
  },
}

export default Format
