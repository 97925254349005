function settingFn(condition) {
  let setting = condition.displaySettings
  let type = setting.mode
  return {
    type: type,
    nameCN: setting.title,
    nameEN: setting.entitle,
    styleSet: setting,
    filterSet: condition.resultFilter,
  }
}

function propFn(list) {
  return list.filter(item => {
    return !item.code.includes('_dim')
  })
}

function groupFn(list) {
  let arr = []
  list.forEach(item => {
    if (item.code.includes('_dim')) {
      arr.push({
        ...item,
        dim_none: true,
      })
    }
  })
  return arr
}

// dim_none: true
const Format = {
  // 属性
  attr(params) {
    this.prop = propFn(params.condition.tableColumns)
    this.groups = groupFn(params.condition.tableColumns)
    this.result = params.result.data
    this.setting = settingFn(params.condition)
  },
  // 指标
  propfun(item, temp, arr) {
    arr.push(temp)
  },
}

export default Format
