function settingFn(condition) {
  let setting = condition.setting
  let type = setting.chartType
  return {
    type: type,
    nameCN: setting.title,
    nameEN: setting.entitle,
    styleSet: setting,
    filterSet: {},
  }
}

// 类型转化
const dataTypeEnum = {
  day: '天',
  week: '周',
  month: '月',
}

const Contrast = {
  // 属性
  attr(params) {
    this.prop = Contrast.propsFn(params.condition)
    this.groups = [{ ...params.condition.selectedGroup, code: 'fgroup' }]
    this.result = Contrast.resultFn(params.result.list)
    this.setting = settingFn(params.condition)
  },
  // 指标初始值
  propsFn(condition) {
    let arr = [{ text: '总人数', code: 'person_number_all' }]
    let duration = parseInt(condition.duration)
    let unit = dataTypeEnum[condition.time_dimension]
    for (let i = 0; i < duration; i++) {
      let temp = {}
      if (i === 0) {
        temp.text = `1${unit}以内`
      } else {
        temp.text = `第${parseInt(i + 1)}${unit}`
      }
      temp.code = `person_number_${i}`
      arr.push(temp)
    }
    return arr
  },
  propfun(item, temp, arr) {
    arr.push(temp)
  },
  // 分组
  // 数据初始值
  resultFn(list) {
    let arr = []
    list.forEach(item => {
      let dimension = item['dimension']
      let findIdx = arr.findIndex(sub => {
        return sub['fgroup_dim'] === dimension
      })
      let temp = {}
      if (findIdx > -1) {
        temp = arr[findIdx]
      } else {
        arr.push(temp)
        temp.person_number_all = item.total
      }
      temp['fgroup_dim'] = dimension
      temp[`person_number_${item.duration}`] = item.person_number
      temp[`rate_${item.duration}`] = item.rate
    })
    return arr
  },
  result(r, item, temp) {
    // 补充有用的数据 rate
    let k = item.alias
    let duration = k.replace('person_number_', '')
    if (duration !== 'all' && r[k] !== undefined) {
      temp[`rate_${duration}`] = (r[`rate_${duration}`] * 100).toFixed(2) + '%'
    }
  },
}

export default Contrast
