import drowsv1 from './drowChart'
export function Retention(_this,query,data){
  // let _this = this
  let option = {}

  let unit = ''
  if(query.time_dimension=='day'){
    unit = "天"
  }else if(query.time_dimension=='week'){
    unit = "周"
  }else if(query.time_dimension=='month'){
    unit = "月"
  }

  switch(query.setting.chartType){
    case 'line':
      getLineBar('line')
      break
    case 'bar':
      getLineBar('bar')
      break
    case 'table':
      getTable()
      break
  }

  function getLineBar(isline){
    let config = {
      legend:[],
      xAxisdata:[],
      seriesdata:[],
      setting:query.setting,
      max:'',
    }
    let lines =[]
    let isHorizontal = config.setting.enableHorizontal && isline === 'bar' //是否横向
    let isStack = query.setting.enableStack && isline === 'bar' //是否堆积
    let isRate = query.setting.enableRate //是否留存率
    
    let code = isRate? 'rate' : 'person_number'
    let numArr = data.list.map((x) => {
      return x[code]
    })
    config.max = Math.max.apply(null, numArr)

    if(query.selectedGroup.code == 'projectID'){
      config.legend = ['总体']
      lines.push(_this.$basicInfo.project.id)
    }else{
      config.legend = data.list.map(x=>{
        return x.dimension
      }).sort().distinct()

      lines = data.list.map(x=>{
        return x.dimension
      }).sort().distinct()
    }
    
    let durationArr= Array.range(1,query.duration)
    config.xAxisdata = durationArr.map(x=>{
      return' 第'+x+unit
    })
    config.xAxisdata = isHorizontal?config.xAxisdata.reverse():config.xAxisdata
    let numbxAxis = isHorizontal?durationArr.reverse():durationArr
    config.setting.direction = isHorizontal?'horizontal':''

    lines.forEach(x=>{
      let ser = {
        name:query.selectedGroup.code == 'projectID'?'总体':x,
        type:isline,
        barMaxWidth: 30,
        lineStyle:{
          type:'solid',
        },
        data:[],
        label:{
          show:query.setting.enableLable,
          color:_this.$basicInfo.project.theme==0?'#444':'#ddd',
          position:isStack?'':(isHorizontal?'right':'top'),
          formatter:function(v){
            if(isRate){
              return  Math.abs(v.value).toFixed(2)
            }else{
              return  _this.$commonJs.formatAxis(_this.$pageunit,config.max,v.value)
            }
          }
        },
        code:0,
        displayName:query.selectedGroup.code == 'projectID'?'总体':x,
        measure:query.selectedGroup.code == 'projectID'?'总体':null,
      }
      numbxAxis.forEach((j)=>{
        let arr = data.list.filter((k)=>{
          return k.duration === j-1 && k.dimension == x
        })
        let result = arr[0]?(isRate?arr[0].rate*100:arr[0].person_number):0
        ser.data.push(result)
        ser.stack = isStack?'堆积'+j:''
      })
      config.seriesdata.push(ser)
    })

    if(isHorizontal){
      config.xAxisdata = config.xAxisdata.map(el => {
        return el.substring(0,10)
      })
    }
    option = drowsv1.drowLine(_this,config)
  }
  function getTable(){
    let tableHead = []
    let tableData = []
    //enableTableRate 占比

    let filter = {}
    filter = {
      key:'dimension',
      label:query.selectedGroup.text,
    }
    tableHead.push(filter)
    filter = {
      key:'total',
      label:'总人数',
    }
    tableHead.push(filter)

    
    let legend = data.list.map(x=>{
      return x.dimension
    }).sort().distinct()

    let xAxisdata = Array.range(1,query.duration).map(x=>{
      return' 第'+x+unit
    })
    xAxisdata.forEach((j,idx)=>{
      filter = {
        key:'person_number_rate'+(idx+1),
        label:j,
      }
      tableHead.push(filter)
    })
    legend.forEach(x=>{
      
      let list = {}
      list.dimension = x
      let totalarr = data.list.filter((k)=>{
        return k.dimension == x
      })
      list.total = totalarr[0].total

      xAxisdata.forEach((j,idx)=>{
        let arr = data.list.filter((k)=>{
          return k.duration === idx && k.dimension == x
        })
        if(query.setting.enableTableRate){
          list['person_number_rate'+(idx+1)] = arr[0]?arr[0].person_number+'('+(arr[0].rate*100).toFixed(2)+'%)':'0'
        }else{
          list['person_number_rate'+(idx+1)] = arr[0]?arr[0].person_number :'0'
        }
        
      })
      tableData.push(list)

    })
    option.data = {
      tableHead:tableHead,
      opt:tableData,
      setting:{
        enableConflation:query.setting.enableConflation,
        enablePivot:query.setting.enablePivot
      }
    }
  }
    
  return option
}

