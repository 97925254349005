const Format = {
  // 属性
  attr(params) {
    this.prop = [{ code: 'user_fixed_user_mid_distinct', text: params.condition.text }]
    this.groups = []
    this.result = [{ user_fixed_user_mid_distinct: params.result.count }]
    this.setting = {type: 'card'}
  },
  // 指标
  propfun(item, temp, arr) {
    arr.push(temp)
  },
}

export default Format
