import store from '@/store'
let drowsv1 = {
  //线图柱图
  drowLine(_this, config) {
    let basicInfo = store.state.basicInfo
    let labelcolor = basicInfo.project.theme == 0 ? '#444' : '#ddd'
    let bordercolor = basicInfo.project.theme == 0 ? '#E9ECEF' : '#555'
    let isHorizontal = config.setting.direction == 'horizontal'
    let isSlider = config.setting.enableSlider

    let gridBottom = 30
    if (!config.setting.enableOptimizexAxis) {
      gridBottom += 35
    }
    if (config.setting.enableLegend) {
      gridBottom += 20
    }
    if (isSlider && !isHorizontal) {
      gridBottom += 15
    }

    let maxLength = 0
    if (config.xAxisdata) {
      config.xAxisdata.forEach(el => {
        let wt = _this.$commonJs.getWordsWidth(el)
        maxLength = wt > maxLength ? wt : maxLength
      })
    }
    let option = {
      grid: {
        top: '20',
        bottom: gridBottom,
        right: '50',
        left: isSlider ? (isHorizontal ? maxLength + 40 : 60) : isHorizontal ? maxLength : 60,
      },
      dataZoom: {
        orient: isHorizontal ? 'vertical' : 'horizontal',
        type: 'slider',
        show: isSlider ? true : false,
        showDetail: false,
        handleIcon:
          'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: '150%',
        borderColor: bordercolor,
        width: isHorizontal ? 10 : '',
        height: isHorizontal ? '' : 10,
        left: isHorizontal ? 10 : 10,
        right: isHorizontal ? 0 : 10,
        top: isHorizontal ? 20 : '',
        bottom: isHorizontal ? 30 : config.setting.enableLegend ? 30 : 10,
      },
      tooltip: {
        trigger: 'axis',
        enterable: true,
        transitionDuration: 0,
        padding: 0,
        textStyle: {
          fontSize: 12,
        },
        extraCssText: 'box-shadow: 0 0 8px 0 rgba(136,152,170,0.15);',
        formatter: function(params, ticket, callback) {
          return _this.$commonJs.getTooltipHtml(params, option.series)
        },
      },
      legend: {
        show: config.setting.enableLegend,
        type: 'scroll',
        data: config.legend,
        bottom: 0,
        textStyle: {
          color: labelcolor,
        },
        pageIconColor: '#909399',
        pageIconSize: [10, 10],
        pageTextStyle: {
          lineHeight: '15',
          color: labelcolor,
        },
      },
      xAxis: {
        type: isHorizontal ? 'value' : 'category',
        // boundaryGap: true,
        data: isHorizontal ? '' : config.xAxisdata,
        axisLabel: {
          color: labelcolor,
          lineStyle: {
            color: bordercolor,
          },
          formatter: function(v) {
            return _this.$commonJs.formatAxis(_this.$pageunit, config.max, v)
          },
          interval: config.setting.enableOptimizexAxis ? 'auto' : 0,
          rotate: config.setting.enableOptimizexAxis ? '' : 45,
        },
        axisLine: {
          show: isHorizontal ? false : true,
          lineStyle: {
            color: bordercolor,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: isHorizontal ? true : false,
          lineStyle: {
            type: 'dotted',
            color: bordercolor,
          },
        },
      },
      yAxis: {
        type: isHorizontal ? 'category' : 'value',
        data: isHorizontal ? config.xAxisdata : '',
        axisLabel: {
          show: true,
          color: labelcolor,
          formatter: function(v) {
            return _this.$commonJs.formatAxis(_this.$pageunit, config.max, v)
          },
        },
        axisLine: {
          show: isHorizontal ? true : false,
          lineStyle: {
            color: bordercolor,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            type: 'dotted',
            color: bordercolor,
          },
        },
      },
      series: config.seriesdata,
    }
    return option
  },
  //漏斗
  drowFunnel(_this, config) {
    let basicInfo = store.state.basicInfo
    let labelcolor = basicInfo.project.theme == 0 ? '#444' : '#ddd'
    let option = {
      grid: {
        top: '20',
        right: '20',
        left: '20',
        bottom: '20',
      },
      tooltip: {
        trigger: 'item',
        textStyle: {
          fontSize: 12,
          color: '#fff',
        },
        extraCssText: 'box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);',
      },
      legend: {
        show: config.setting.enableLegend,
        data: config.legend,
        orient: 'vertical',
        left: 'right',
        bottom: '30',
        textStyle: {
          color: labelcolor,
        },
        pageIconColor: '#909399',
        pageIconSize: [10, 10],
        pageTextStyle: {
          lineHeight: '15',
          color: labelcolor,
        },
      },
      series: config.seriesdata,
    }
    return option
  },
  //关系图
  drowRelation(_this, config) {
    let option = {
      grid: {
        top: '20',
        right: '10',
      },
      tooltip: {
        trigger: 'item',
        triggerOn: 'click',
        enterable: true,
        transitionDuration: 0,
        // padding: 0,
        textStyle: {
          fontSize: 12,
        },
        extraCssText: 'box-shadow: 0 0 8px 0 rgba(136,152,170,0.15);',
        formatter: function(params, ticket, callback) {
          //点击圆点触发
          if (params.data.id) {
            var tip = ''
            var row
            for (var i = 0; i < params.data.source.length; i++) {
              row = params.data.source[i]
              if (row.itemset_a == params.data.id) {
                tip += "<div style='padding:2px 0px 3px 5px;'>" + row.itemset_b + ' : ' + row.supportcount + '&nbsp&nbsp' + '</div>'
              }
            }
            var txt = "<div style='padding:2px 0px 3px 5px;'>关联人数</div>"
            tip = tip == '' ? tip : txt + tip
            return tip
          }
        },
      },
      series: {
        type: 'graph',
        layout: 'force',
        force: {
          repulsion: 100,
          edgeLength: [20, 400],
        },
        roam: true,
        focusNodeAdjacency: true,
        animation: true,
        animationThreshold: 10,
        lineStyle: {
          show: true,
          normal: {
            width: 0.5,
            curveness: 0.3,
            opacity: 0.7,
          },
        },
        label: {
          position: 'right',
          show: true,
          // color:_this.$basicInfo.project.theme==0?'#444':'#ddd',
        },
        nodes: config.nodesA,
        edges: config.edges,
      },
    }
    return option
  },
  //桑基图
  drowSankey(_this, config) {
    let option = {
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        extraCssText: 'box-shadow: 0 0 8px 0 rgba(136,152,170,0.15);',
        formatter: function(x) {
          let per = x.value / config.allnum
          let html = x.name + ': ' + x.value + ' (' + (per * 100).toFixed(1) + '%)'
          return html
        },
      },
      series: {
        type: 'sankey',
        right: 10,
        data: config.data,
        links: config.links,
        label: {
          show: config.setting.enableLable,
          color: _this.$basicInfo.project.theme == 0 ? '#444' : '#ddd',
        },
      },
    }
    return option
  },
}

export default drowsv1
