import request from '@/utils/request'

// 指标
export function behaviorLoad(param, data) {
  return request(
    {
      url: '/Behavior/load',
      params: param,
      data: data,
    },
    'get'
  )
}

// Search
export function Search(temp, param, data) {
  return request(
    {
      url: '/' + temp + '/Search',
      params: param,
      data: JSON.stringify(data),
    },
    'post'
  )
}

//漏斗图 load
export function funnelLoad(param, data) {
  return request(
    {
      url: '/Funnel/Load',
      params: param,
      data: data,
    },
    'get'
  )
}

//批量修改时间
export function updateTimeRange(param, data) {
  return request(
    {
      url: '/V2/dashboard/UpdateTimeRange',
      params: param,
      data: data,
    },
    'post'
  )
}
